/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.desktop-sidebar {
  display: block;
}

//Begin moving from app.component.css
h1 {
  color: #369;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 300%;
}

div#wrapper {
  flex-direction: row;
  display: flex;
}

@media screen and (min-width: 769px) {
  div#wrapper {
    display: flex;
  }
}

app-sidebar {
  flex: 0 0 auto;
}

app-header {
  flex: auto;
  height: 71px;
}

app-footer {
  flex: auto;
  height: 0px;
}

main {
  height: calc(100vh - 100px);
  position: relative;
  flex: auto;
  margin-top: 8px;
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.mode-script-import .apply-in-default-mode {
  display: none
}

.mode-prescriber .apply-in-default-mode {
  display: none
}

.mode-default .apply-in-script-import-mode {
  display: none
}

.mode-prescriber .apply-in-script-import-mode {
  display: none
}

.mode-default .apply-in-prescriber-mode {
  display: none
}

.mode-script-import .apply-in-prescriber-mode {
  display: none
}

.mode-prescriber .hide-in-prescriber-mode {
  display: none
}

.mode-default .hide-in-default-mode {
  display: none
}

.mode-script-import .hide-in-script-import-mode {
  display: none
}

.mat-dialog-container {
  /* border-radius: 15px !important; */
  /* border:solid 4px #B0C9E9; */
  border-radius: 5px;
  padding: 0 !important;
}

.download-dialog .mat-dialog-container {
  border-radius: 0px !important;
  border: none;
  padding: 0 !important;
}

body input,
body textarea,
body button,
body select,
body label {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #4e4e53;
}

body {
  padding: 0;
  margin: 0;
}

.likeLink:not([href]):not([tabindex])[disabled='true'] {
  cursor: default;
  color: rgba(2, 117, 216, 0.5);
}

.likeLink:not([href]):not([tabindex]):not([disabled='true']) {
  cursor: pointer;
  color: #004DD1;
}

.likeLink:not([href]):not([tabindex]):not([disabled='true']):hover {
  cursor: pointer;
  color: #004DD1;
  text-decoration: underline;

}

.inverse-btn {
  border: 1px solid #4e4e53;
  box-sizing: border-box;
  padding: 2px 10px 2px 10px;
  vertical-align: middle;
  line-height: 29px;
  border-radius: 7px;
  background-color: transparent;
  color: #4e4e53;
  cursor: pointer;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

/**
 * Tooltip Styles
 */

/* Base styles for the element that has a tooltip */
[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%;
}

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -80px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px);
}

/* Left */
.tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}

.tooltip-left:before {
  margin-left: 0;
  margin-right: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-left-color: #000;
  border-left-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-left:hover:before,
.tooltip-left:hover:after,
.tooltip-left:focus:before,
.tooltip-left:focus:after {
  -webkit-transform: translateX(-12px);
  -moz-transform: translateX(-12px);
  transform: translateX(-12px);
}

/* Bottom */
.tooltip-bottom:before,
.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  left: 50%;
}

.tooltip-bottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #000;
  border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after {
  -webkit-transform: translateY(12px);
  -moz-transform: translateY(12px);
  transform: translateY(12px);
}

/* Right */
.tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%;
}

.tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  transform: translateX(12px);
}

/* Move directional arrows down a bit for left/right tooltips */
.tooltip-left:before,
.tooltip-right:before {
  top: 3px;
}

/* Vertically center tooltip content for left/right tooltips */
.tooltip-left:after,
.tooltip-right:after {
  margin-left: 0;
  margin-bottom: -16px;
}

.header-container-right {
  height: 70px;
  margin-top: 5px;
  display: block;
  padding-left: 0px;
  margin-left: -35px;
  justify-content: space-between;
}

.approve-button.inverse-btn[disabled] {
  background-color: #A6A6A9 !important;
  border: none;
  cursor: default;
}

.approve-button {
  background-color: #053057 !important;
  color: white;
  border-radius: 40px;
  border: 2px solid white;
  height: 50px;
  margin: 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  min-width: 40%;
  line-height: 1.2em;
}

.approve-button:focus {
  box-shadow: 0 0 3pt 2pt rgb(77, 144, 254);
  outline: none;
}

.approve-button.inverse-btn[disabled] {
  background-color: #A6A6A9 !important;
  border: none;
  cursor: default;
}

// HEADER
.logout {
  margin-right:10px; 
}
.logout a {
  text-decoration:none;
  font-size: 14px;
}

.secondary-button-disabled {
  font-family: "Roboto Medium", "Roboto Regular", Roboto;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  border: 0;
  border-radius: 4px;
  padding: 5px;
  text-transform: uppercase;
  color: gray;
  cursor: unset;
}

.link-like-button {
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  vertical-align: middle;
}

.link-like-button.inverse-btn[disabled] {
  color: #A6A6A9 !important;
  text-decoration: none;
  cursor: default;
}

.header-container-text {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  min-width: 15%;
  text-align: center;
}

.right-header-text {
  text-align: center;
  font-size: 14px;
  max-width: 45%;
  white-space: nowrap;
  padding: 0 0 0 0;
  margin: 0 0 0 10px;
}

@media screen and (min-width: 850px) {
  .right-header-text {
    font-size: 15px;
  }
}

@media screen and (min-width: 910px) {
  .right-header-text {
    font-size: 16px;
  }
}

@media screen and (min-width: 970px) {
  .right-header-text {
    font-size: 17px;
  }
}

@media screen and (min-width: 1020px) {
  .right-header-text {
    font-size: 18px;
  }
}

@media screen and (min-width: 1070px) {
  .right-header-text {
    font-size: 19px;
  }
}

@media screen and (min-width: 1120px) {
  .right-header-text {
    font-size: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .search-header-field {
    display: none;
  }
}

.page-menu {
  position: relative;
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
  height: 30px;
  z-index: 100;
  justify-content: space-around;
}

.pageswitch {
  display: block;
  color: #053057;
  font-weight: bold;
  flex-basis: auto;
}

.active-link {
  color: #053057;
  font-weight: bold;
  border-bottom: 3px solid #053057;
  text-decoration: none;
}

.header-logo {
  margin-right: 0px;
  padding-left: 15px;
  min-height: 20px;
  padding-right: 3%
}

.header-logo img {
  position: relative;
  right: 0px;
  text-align: center;
  display: block;
  height: 28px;
  object-fit: contain;
  cursor: pointer;
  float: right;
  padding-top: 4px;
  padding-bottom: 4px;
}


label {
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
}

.actionPanel {
  margin-right: 20px;
  /*  padding-right:25px;*/
}

.header-container-left {
  padding: 15px 5px 5px 0;
  height: 70px;
}

.header-box {
  padding: 15px;
  height: 70px;
}

.actionPanel {
  margin-bottom: -70px;
  position: relative;
  bottom: 70px;
  background-color: #053d57 !important;
  color: white;
  border-radius: 40px;
  /*  float: left;*/
  height: 70px;
  margin-top: 5px;
  display: flex;
  padding: 0px 3px 5px 0;
  justify-content: space-between;
}


.pageContent-notflex {
  /* same as .pageContent but without display:flex */
  height: 95%;
  background-color: #FFF;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .pageContent {
    margin-left: 12px;
  }

  .pageContent-notflex {
    margin-left: 12px;
  }
}

.surgery-details-page {}

.cdk-virtual-scroll-viewport {
  background-color: #FFF;
}

.app-progress-bar {
  height: 30px !important;
  color: #053d57 !important;
  background-color: #053d57 !important;
}

mat-progress-bar .mat-progress-bar-buffer {
  background: #cdd8dd;
}

.mat-progress-bar-fill::after {
  background-color: #053d57 !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.spin {
  animation: rotation 2s infinite linear;
}

.spin-reverse {
  animation: rotation 2s infinite reverse linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

div.sidebar {
  padding: 5px;
  margin: 0px 5px 0px 5px;
  background:#00217f;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 175px;
  height: 100%;
}

div.sidebar .brand {
  padding: 5px;
  font-size: 1.2em;
  height: 48px;
  color: white;
}

.small {
  font-size: 0.8em;
}

.link-like-button.menu-item {
  padding: 5px;
  margin-top: 5px;
  border: solid 2px #053057;
  border-radius: 4px;
  color: #053057;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.link-like-button.menu-item.disabled {
  border: solid 2px gray;
  background: silver;
  text-transform: uppercase;
  cursor: default;
  color: gray;
  font-weight: bold;
}

.link-like-button.menu-item.current {
  background-color: #00217f;
  cursor: default;
}

.link-like-button.menu-item.current {
  color: white;
}

.link-like-button.menu-item.current:hover {
  color: white;
  text-decoration: none;
}

.link-like-button.menu-item:hover {
  background-color: #009cdc;
  color: #FFF;
}

/*a {
  color: #053d57 ;
  font-weight: bold;
  text-decoration: none !important;
}

a:hover {
  color: #FF6A07;
}*/

.fl {
  float: left;
}

.fr {
  float: right;
}

.cl {
  clear: both;
}

.usernameDiv {
  padding-top: 10px;
  padding-right: 10px;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: #efefef;
  font-size: 14px;
  margin-bottom: 180px;
  overflow: hidden;
  padding-bottom: 20px;

}

:root {
  --primary-color: #053057;
  --secondary-color: #009cdc;
}

.header {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  margin: 0px;
  background:#00217f;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: white;
}

.page-title {
  flex: 1;
  font-size: 1.4em;
  font-weight: bold;
}

.sidebar-icon {
  cursor: pointer;
  position: unset;
  left: unset;
  margin-right: 18px;
  margin-left: auto;
}

.disabled {
  color: gray;
  cursor: unset;

}

.header A.disabled {
  cursor: default;
}

.help,
.faq,
.invite {
  flex: 0 0 auto;
  min-width: max-content;
  padding-right: 32px;
  text-transform: uppercase;
  font-size: 1em;
  vertical-align: top;
  line-height: 24px;
}

.logout {
  width: 54px;
}

.invite a span {
  vertical-align: top;
}

.help {
  /* display: none; */
}

.header A {
  cursor: pointer;
  color: white;
}


.announcement {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 13px;
}

.announcement-small {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 10px;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.main-container {
  width: 1120px;
  display: table;
}

.left-section,
.right-section {
  display: table-cell;
  text-align: left;
  vertical-align: top;
}

.left-section {
  width: 170px;
  background-color: #b2bb1c;
  padding: 15px;
  word-wrap: break-word;
}

.link-box a {
  display: block;
  padding: 15px 10px;
  background-color: #719312;
  color: #FFFFFF;
  border-radius: 4px;
  margin-bottom: 10px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
}

.link-box a.active {
  background-color: #316c09;

}

.grey-strip {
  background: url('../images/grey-strip.gif') repeat-x top left;
}

.top-section {
  height: 68px;
  padding-left: 40px;
  display: block;
  vertical-align: middle;
  padding-top: 15px;
  box-sizing: border-box;
}

.link-back {
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #000000;
  margin-right: 20px;
  vertical-align: middle;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.link-back .icon-chevron-left {
  font-size: 20px;
  vertical-align: middle;
}

/*.custom-select{
  border-radius:4px;
  background-color:#316c09;
  padding:10px;
  color:#FFFFFF;
}*/
.custom-select select,
select.form-select {
  border: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #316c09 url('../images/select-arrow.png') no-repeat 95% center;
  text-indent: 0.01px;
  text-overflow: "";
  color: #FFF;
  border-radius: 2px;
  padding: 9px 20px 9px 9px;
}

.custom-select select::-ms-expand,
.form-select::-ms-expand {
  display: none;
}

.custom-select select.item-count,
select.form-select {
  border: 1px solid #858585 !important;
  background: #FFF url('../images/select-arrow-black.png') no-repeat right center;
  color: #4e4e53;
  padding: 6px 20px 6px 6px;
}

.custom-select select option {
  background-color: #FFF;
  color: #4e4e53;
}

.page-content {
  padding: 20px;
}

/*accordion css */
.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  margin-top: -13px;
  left: 15px;
}

.ui-accordion-header {
  background-color: #ccc;
  padding: 11px 40px !important;
  font-size: 14px;
  text-transform: uppercase;
  outline: none;
  border-radius: 0px !important;
  border: 0px !important;
  background-image: none;
  color: #4e4e53;
}

.ui-icon-circle-arrow-s,
.ui-icon-circle-arrow-e {
  background-image: none !important;
  text-indent: inherit;
  width: 20px;
  height: 20px;
}

.ui-icon-circle-arrow-s:before {
  content: "\f078";
  font-size: 20px;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.ui-icon-circle-arrow-e:before {
  content: "\f053";
  font-size: 20px;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

span.ui-accordion-header-icon {
  vertical-align: middle;
  margin-right: 7px;
  display: inline-block;
  font-size: 20px;
  width: 20px;
  height: 20px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.ui-accordion-header.ui-state-active {
  margin-bottom: 0px;
  margin-top: 0;
}

.ui-accordion .ui-accordion-content {
  background-color: #fff;
  padding: 30px 15px 15px;
  margin-bottom: 20px;
}

.ui-tabs.ui-widget-content {
  padding: 0;
}

.ui-widget-content {
  background: none;
  border: 0;
  border-radius: 10px !important;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 13px;
  vertical-align: middle;
}

.ui-accordion-content p {
  margin: 0;
}

.form-label {
  display: inline-block;
  text-align: right;
  font-size: 14px;
  vertical-align: middle;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  line-height: 1.1;
}

.form-control {
  border: 1px solid #868686;
  border-radius: 2px;
  padding: 7px;
  vertical-align: middle;
  box-sizing: border-box;
}

.form-group {
  margin-bottom: 10px;
  font-size: 0;
}

.btn-primary {
  font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  background-color: #053057;
  color: #fff;
  border: 0;
  border-radius: 4px;
  padding: 5px;
}

.btn-primary:disabled {
  color: gray;
  cursor: unset;
}

.form-group:after {
  content: "";
  display: block;
  clear: both;
}

.ui-widget-content.ui-datepicker {
  background: inherit;
}

button.ui-datepicker-trigger {
  position: relative;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  content: "";
  border: 0px;
  background: none !important;
  width: 17px;
  height: 17px;
  vertical-align: bottom;
}

button.ui-datepicker-trigger:before {
  content: "\f073";
  font-family: Roboto, Arial, Helvetica, sans-serif;
  width: 17px;
  height: 17px;
  display: inline-block;
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 17px;
  background: #FFF;
}

.ui-tabs .ui-tabs-nav {
  padding: 0px;
  border: 0px;
  background-color: #ccc;
  background-image: none;
  border-radius: 0;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #4e4e53;
  text-decoration: none;
  outline: none;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: #4e4e53;
  outline: none;
}

.ui-tabs .ui-tabs-nav li {
  border: 0px;
  background: none;
  outline: none;
  font-size: 14px;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active {
  border: 0px;
  border-radius: 0px;
  margin-top: 0;
  padding-top: 1px;
  background: #FFF;
}

.ui-tabs .ui-tabs-panel {
  background-color: #fff;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  padding-top: 25px;
}

.action-links {
  text-align: right;
  font-size: 13px;

}

.action-links a {
  text-decoration: none !important;
  margin-left: 20px;
  text-transform: uppercase;
  color: #4e4e53;
  vertical-align: middle;
  outline: none;
}

.action-links i {
  font-size: 18px;
  vertical-align: middle;
  margin-left: 5px;
}

.text-green {
  color: rgb(113, 147, 18);
}

.text-marun {
  color: rgb(183, 14, 14);
}

.history-grid {
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
}

.history-grid th {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  font-size: 13px;
  padding: 8px 10px;
  letter-spacing: 0.5px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  ;
}

.history-grid td {
  border-bottom: 1px solid #ccc;
  padding: 8px 10px;
  font-size: 13px;
}

.history-grid tr:hover td {
  background-color: #e5e5e5;
  position: relative;
}

.history-grid tr:hover td:last-child a.edit-link {
  display: block;
}

.pagination {
  font-size: 14px;
}

.pagi-title {
  color: grey;
}

.pagination-right {
  float: right;
}

.pagination-left {
  float: left;
  color: grey;
}

.submit-btn {
  border: 0;
  padding: 2px 10px 2px 10px;
  vertical-align: middle;
  line-height: 31px;
  border-radius: 7px;
  background-color: #053057;
  color: #ffffff;
  cursor: pointer;
}

.cancel-btn {
  border: 0;
  padding: 2px 10px 2px 10px;
  vertical-align: middle;
  line-height: 31px;
  border-radius: 7px;
  background-color: grey;
  color: #ffffff;
  cursor: pointer;
}

.faded-border {
  border-color: #dbdbdc;
  border-width: 2px;
}

.next-btn,
.prev-btn,
.current-count {
  border: 1px solid #999;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  font-size: 20px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  line-height: 30px;
  float: left;
}

.prev-btn {
  border-radius: 4px 0 0 4px;
}

.current-count {
  border-radius: 0 4px 4px 0;
  border-left: 0px;
  font-size: 13px;
}

.total-count {
  display: inline-block;
  margin-top: 8px;
  margin-left: 15px;
  margin-right: 15px;
  float: left;
}

.ui-tabs .ui-tabs-nav li a,
.ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active a {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.level-1 {}

.col-1,
.col-2,
.col-3 {
  vertical-align: middle;
}

.col-3 {
  width: 70%;
}

/*.col-1{
  width:40%;
}*/
.col-2 {
  width: 350px;
  display: inline-block;
}

.col-1>.form-label {
  width: 35%;
}

.col-1>.form-control {
  width: 58%;
}

.col-2>.form-label {
  width: 32%;
}

.col-2>.form-control {
  width: 65%;
}

.col-3>.form-label {
  width: 20%;
}

.col-3>.form-control {
  width: 78.8%;
}

.sub-act-link {
  text-decoration: none;
  display: inline-block;
  vertical-align: bottom;
  width: 18px;
  height: 18px;
  font-size: 18px;
  margin-left: 5px;
}

.edit-link {
  display: none;
  position: absolute;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  text-decoration: none;
  vertical-align: middle;
  right: 10px;
  top: 8px;
}

.top-right-section {
  float: right;
  font-size: 12px;
  text-align: right;
  padding-top: 10px;
}

.top-right-section a {
  color: #4e4e53;
  text-decoration: none;
  text-transform: uppercase;
  margin-left: 10px;
}

.sub-accordion .ui-accordion-header {
  background: #e5e5e5;
  padding-left: 11px !important;
  font-size: 14px;
  text-transform: initial;
  font-weight: normal !important;
  color: #4e4e53;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  margin-top: 0;
}

.ui-accordion.sub-accordion .ui-accordion-header .ui-accordion-header-icon {
  left: inherit;
  right: 15px;
  color: #919191;
}

.ui-accordion.sub-accordion .ui-accordion-content {
  padding: 0;
  font-family: inherit;
}

.ui-accordion.sub-accordion .ui-accordion-content .history-grid {
  margin-top: 0;
}

.custom-check {
  display: none;
  position: absolute;
}

.custom-check+label {
  padding-left: 28px;
  height: 17px;
  position: relative;
  display: inline-block;
}

.custom-check+label:before {
  display: block;
  content: "\f096";
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 22px;
  height: 22px;
  width: 22px;
  margin-top: -12px;
  color: #868686;
}

.custom-check:checked+label:after {
  display: block;
  content: "\f00c";
  position: absolute;
  left: 3PX;
  top: 1PX;
  font-size: 11px;
  color: #cb2020;
}

/*input[type="text"]{*/
/*border:1px solid #868686;*/
/*padding:5px;*/
/*vertical-align:middle;*/
/*}*/
/*input[type="password"]{*/
/*border:1px solid #868686;*/
/*padding:5px;*/
/*vertical-align:middle;*/
/*}*/

.width-100 {
  width: 100%;
  box-sizing: border-box;
}



.grey-text,
.sub-act-link.grey-text {
  color: #858585;
}

.text-area {
  width: 100%;
  padding: 5px;
  border: 1px solid #858585;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 5px;
}

.error-view {
  color: #c10000;
  margin-bottom: 15px;
  line-height: 1.5;
  font-size: 13px;
}

.success-view {
  color: #316c09;
  margin-bottom: 15px;
}

.error-view p,
.success-view p {
  margin: 0px;
}

select.error,
.error {
  border: 1px solid #c10000 !important;
  color: #cb2020;
}

.with-circle {
  background-color: #316c09;
  color: #ffffff;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
}

.page-header {
  background-color: #cccccc;
  font-size: 14px;
  font-weight: normal;
  padding: 10px;
  margin: 0;
}

.page-inner-content {
  background-color: #fff;
  box-sizing: border-box;
  padding: 15px;
}

.filter-box {
  border-bottom: 1px solid #cccccc;
  padding: 0 0 15px;
  margin-bottom: 25px;
}

.btn-sm {
  padding: 3px 8px;
  cursor: pointer;
}

.row-action {
  position: absolute;
  right: 10px;
  top: 4px;
  display: none;
}

.history-grid tr:hover td:last-child .row-action {
  display: block;
}

/* dialog Css */
.ui-widget-overlay {
  background: #666666 !important;
}

.ui-dialog.ui-widget {
  background: #FFF;
  padding: 0px;
}

.ui-dialog-titlebar.ui-widget-header {
  background: url('../images/dialog-title.jpg') no-repeat left center;
  border: 0px;
  border-radius: 0px;
  color: #4e4e53;
  font-size: 14px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  height: 75px;
  padding: 0px;
}

.ui-state-default .ui-icon {
  background-image: none !important;
}

.ui-dialog .ui-dialog-titlebar-close:before {
  content: "\2716";
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 18px;
  background: transparent !important;
  position: absolute;
  margin: 2 2 2 2;
  position: absolute;
  right: 2px;
  top: 2px;
  color: #ffffff;
  border: 0;
}

.ui-dialog .ui-dialog-titlebar-close {
  background: transparent !important;
  position: absolute;
  margin: 0 0 0 0;
  position: absolute;
  right: 5px;
  top: 2px;
  border: 0;
}

.submit-btn.ui-button,
.grey-btn.ui-button {
  border: 0;
  padding: 8px 17px;
  background-color: #316c09;
}

.grey-btn.ui-button {
  background-color: #707070;
}

.submit-btn.ui-button .ui-button-text,
.grey-btn.ui-button .ui-button-text {
  padding: 0px;
  font-size: 14px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #FFF;
}

body.landing-home .main-container {
  margin: 0 auto;
}

body.landing-home .header-wrapper {
  z-index: 1;
  position: relative;
}

.header-right {
  float: right;
  font-size: 0;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  margin-left: auto;
}

.header-right>p {
  font-size: 20px;
  line-height: 80px;
  height: 80px;
  display: inline-block;
  margin: 0;
  font-weight: bold;
}

.icon-phone {
  width: 20px;
  height: 80px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  font-size: 20px;
  line-height: 73px;
}

.header-img {
  box-sizing: border-box;
  width: 70%;
  background: url("../images/header-img.png") no-repeat center bottom;
  height: 449px;
  float: left;
}

i.premimum-trial-img {
  background: url("../images/landingpage-premtrial.png") no-repeat center bottom;
  display: table-cell;
  bottom: -10px;
  position: relative;
  width: 350px;
}

.header-form {
  box-sizing: border-box;
  width: 430px;
  ;
  float: right;
  height: 449px;
  padding: 10px;
}

.account-container {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
}

.form-header {
  font-weight: 300;
  margin-top: 40px;
  margin-bottom: 30px;
}

.settings-form {
  background-color: #ffffff;
  padding: 0px 20px 16px 20px;
}

.error-validate {
  position: absolute;
}

.mat-full-width {
  width: 100%;
  height: 58px;
  font-size: 18px;
  font-weight: 400;
  color: #222222;
}

.row-date {
  border-bottom: 1px solid #E6EEF1;
}

.opening-row {
  padding: 9px 0px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
}

.opening-header {
  padding: 10px 0;
  display: grid;
  grid-template-columns: auto auto auto;
  margin-left: -24px;
}

.opening-column {
  width: 100%;
  color: #222222;
  font-size: 16px;
}

.full-width {
  width: 100%;
}

.header-text {
  margin: 16px 20px 8px 20px;
}

.mat-select-arrow-wrapper {
  display: inline-flex !important;
}

.title-day {
  display: flex;
  align-items: center;
  width: 0;
}

.last-column {
  padding-left: 10px;
}

.opening-row {
  .selectTime {
    width: 100%;
    height: 40px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

}

.settings-group {
  margin-bottom: 10px;
  font-size: 0;
}

.settings-group bold {
  font-weight: bold;
}

.settings-group label,
.settings-group input,
.settings-group select {
  margin-bottom: 6px;
}

.settings-group input,
.settings-group select {
  margin-right: .3em !important;
}

.settings-group label {
  font-size: 14px;
}

.settings-group span {
  line-height: 29px;
  font-size: 14px;
}

.settings-group h3 {
  font-size: 14px;
  color: #053057;
  font-weight: bold;
  margin-bottom: .5em;
  margin-top: .5em;
}

.settings-group h2 {
  font-size: 16px;
  color: #053057;
  font-weight: 500;
  margin-bottom: .5em;
  margin-top: .5em;
}

.settings-group select {
  padding: 2px 0px 0px 6px;
}

.settings-group input:disabled,
.settings-group select:disabled {
  background-color: rgb(235, 235, 228);
  color: rgb(84, 84, 84);
}

.form-group label {
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  vertical-align: middle;
}

.form-group ul,
.form-group li {
  font-size: 14px;
}

.form-group input[type="text"],
.form-group input[type="password"],
.form-group select {
  border: 1px solid #c6c6c6;
  border-radius: 3px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 13px;
  margin-right: 0px;
  font-weight: normal;
  display: inline-block;
  width: 400px;
}

input[type="text"].form-input,
input[type="password"].form-input {
  border: 1px solid #c6c6c6;
  border-radius: 3px;
  padding: 8px;
  width: 320px;
  box-sizing: border-box;
  font-size: 16px;
  height: 44px;
}

input[type="password"].form-input {
  letter-spacing: .1em;
}

input[type="text"].form-input-readonly {
  border-radius: 3px;
  padding: 8px;
  width: 320px;
  box-sizing: border-box;
  font-size: 16px;
  height: 44px;
  background-color: #f1f1f1;
  pointer-events: none;
}

.password-form label {
  display: inline-block;
  width: 110px;
  font-size: 13px;
  font-weight: 300;
  vertical-align: middle;
}

.password-form ul,
.password-form li {
  font-size: 14px;
}

.password-form input[type="text"],
.password-form input[type="password"],
.password-form select {
  border: 1px solid #c6c6c6;
  border-radius: 3px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 13px;
  margin-right: 0px;
  font-weight: normal;
  display: inline-block;
  width: 400px;
}

.settings-nav {
  width: 100%;
  float: left;
  padding: 10px;
  height: 100%;
  margin-right: 30px;
}

.right-arrow:after {
  content: '\25BA';
  display: inline-block;
  position: absolute;
  padding-left: 20px;
}

.settings-nav a {
  color: #4D4D53;
  display: block;
  text-decoration: none;
  width: 100%;
  text-align: right;
}

.settings-nav ul {
  display: inline-table;
  height: 350px;
  border-right: thin #4D4D53 solid;
}

.settings-nav li {
  display: block;
  text-decoration: none;
  padding-bottom: 20px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  margin-right: 60px;
}

.fp-container {
  display: block;
  text-align: right;
  font-size: 14px;
  margin-top: 15px;
}

.fp-container a {
  text-decoration: none;
  color: #4e4e53;
  font-weight: 700;
}

.btn-orange {
  min-width: 50px;
  margin-left: 0px !important;
  outline: none;
}

.footer-box {
  background-color: #053d57;
  color: #FFFFFF;
  width: 100%;
  min-width: 600px;
}

.footer {
  bottom: 0;
  width: 100%;
  height: 70px;
  position: fixed;
  /* Set the fixed height of the footer here */
  background-color: #435c6f;
}

.green-strap {
  height: 42px;
  background-color: #b2bb1c;
  margin-bottom: 20px;
}

.red-strap {
  height: 12px;
  background-color: #053057;
  width: 100%;
}

.copyright-box {
  background-color: #053d57;
  color: #FFFFFF;
  font-size: 12px;
  padding: 10px;
}

.copyright-box a {
  text-decoration: none;
  color: #FFFFFF;
  padding: 8px;
}

.copyright-box a:hover {
  text-decoration: underline;
}

div.txt-info {
  font-size: 12px;
  line-height: 1.8;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.txt-info a {
  color: #FFFFFF;
}

.txt-info:before {
  position: absolute;
  font-size: 16px;
  display: inline-block;
  width: 16px;
  line-height: 1.4;
  text-align: center;
  color: #787878;
  margin-left: -20px;
}

.footer-title {
  font-size: 14px;
  margin-top: 0px;
  color: white;
}

.icon-envelope:before {
  font-size: 12px;
}

input[type="text"].news-email {
  padding: 8px 10px;
  border-radius: 5px;
  background-color: #e6e6e6;
  border: 0;
  outline: none;
}

.lt-green-btn {
  background-color: #9db43b;
  padding: 8px 10px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.link-group {
  display: inline-block;
  padding-top: 16px;
}

.header-right .top-link {
  border-left: 1px solid #b2b2b2;
  border-right: 1px solid #b2b2b2;
  vertical-align: middle;
  display: inline-block;
  margin-right: 5px;
  padding: 15px 15px;
  color: #4e4e53;
  text-decoration: none;
  font-size: 14px;
  height: inherit;
  line-height: 1.2;
}

.field-list {
  list-style: none;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0;
  padding-left: 0px;
}

.field-list:after {
  content: " ";
  display: block;
  clear: both;
}

.field-list li {
  float: left;
  margin-right: 10px;
}

.field-list li label {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: -2px;
}

.field-list li select.form-select {
  border: 1px solid #c1c1c1 !important;
  background: #fafafa url('../images/select-arrow-black.png') no-repeat right center;
  color: #000;
  padding: 6px 20px 6px 6px;
  outline: none;
}

.data-table {
  border-collapse: collapse;
  margin-bottom: 20px;
}

.data-table th,
.data-table td {
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  padding: 8px 8px 8px 8px;
  box-sizing: border-box;
  vertical-align: baseline;
}

.data-table label {
  width: auto;
}

.data-table th {
  background-color: #435C6F;
  color: #ffffff;
  font-size: 16px;
  border: 1px solid #435C6F;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.data-table th>label {
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;

  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */

  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
}

.data-table td {
  background-color: #ffffff;
  border: 1px solid #cdcdcd;
}

.data-table tr:nth-child(odd) td {
  background-color: #f2f2f2;
}

.pagination {
  padding: 10px 0;
  margin: 10px 0;
}

.noteBox {
  font-size: 11px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.pagination-list span {
  color: #435C6F;
}

.pagination-list span>a {
  text-decoration: none;
  color: #4e4e53;
}

.pagination-list>a.active {
  background-color: #435C6F !important;
}

.pagination-list {
  font-size: 0;
}

.pagination-list>a.prev,
.pagination-list>a.next {
  font-size: 30px;
  display: inline-block;
  padding: 0;
  background-color: #d2d2d3;
  text-decoration: none;
  color: #FFF;
  /* height: 25px; */
  width: 1em;
  line-height: 1.1;
  border-radius: 6px;
  vertical-align: bottom;
  text-align: center;
}

.pagination-list>* {
  margin-left: 7px;
}

/*a.prev > i::before, a.next > i::before {
    width: 12px;
}*/

.header-right a.top-wc {
  text-transform: inherit;
}

.header-right a.top-wc:hover {
  background-color: transparent;
  position: relative;
}

.sub-menu {
  position: absolute;
  background-color: #FFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  display: none;
}

.sub-menu a,
.sub-menu a:hover {
  text-transform: inherit;
  padding: 0;
  line-height: 1.2;
  height: auto;
  background-color: transparent;
}

.sub-menu p {
  margin: 0;
}

.sub-menu button {
  margin: 10px 0;
}

.sub-menu:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  border-top: 0;
  position: absolute;
  left: 25%;
  top: -10px;
}

.overPop:hover .sub-menu {
  display: block;
}

.data-table th a {
  text-decoration: none;
  color: #ffffff;
  font-size: 23px;
  line-height: 1.2;
  display: inline-block;
  height: 16px;
  vertical-align: top;
}

.data-table th a i:before,
.data-table th a i {
  line-height: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: text-top;
  margin-left: 2px;
  margin-top: -1px;
}

.tableTitle {
  font-size: 16px;
  color: #b2bb1c;
  margin-bottom: 5px;
  display: inline-block;
}

.spacer {
  height: 25px;
}

.btn-arrow:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.btn-arrow:before {
  border-color: rgba(0, 0, 0, 0);
  border-top-color: #4e4e53;
  border-width: 12px;
  margin-left: -12px;
}

div.vertical-line {
  width: 1px;
  /* Line width */
  background-color: black;
  /* Line color */
  display: inline-block;
  height: 42px;
  /* Override in-line if you want specific height. */
  margin: 19px 10px 0px 10px;
}


button.btn-premium {
  border: 1px solid transparent;
  border-width: 1px;
  border-color: #e99009;
  box-sizing: border-box;
  padding: 5px;
  vertical-align: middle;
  line-height: 13px;
  font-size: 13px;
  white-space: nowrap;
  border-radius: 7px;
  background-color: transparent;
  color: #e99009;
  cursor: pointer;
}

.dropdown-content a {
  color: #ffffff;
  text-decoration: none;
}

/* change to a:hover for dropdown */
.dropdown-content a {
  color: #ffffff;
  background-color: transparent;
  text-decoration: none;
}

p.username {
  font-size: 14px;
  line-height: 14px;
  text-transform: lowercase;
  letter-spacing: 0.5px;
}

.primary-color {
  color: #053057;
}

.muted-color {
  color: #a6a6a8;
}

.primary-bg {
  background-color: #435C6F;
  color: white;
}

.muted-bg {
  background-color: #435C6F;
  color: white;
}

th.primary-bg {
  background-color: #053057;
  border-color: #053057;
}

.primary-grad-bg {
  background: -webkit-linear-gradient(#435C6F, #4F768C);
  background: -o-linear-gradient(#435C6F, #4F768C);
  background: -moz-linear-gradient(#435C6F, #4F768C);
  background: linear-gradient(#435C6F, #4F768C);
  color: white;
}


th.primary-grad-bg {
  background: -webkit-linear-gradient(#435C6F, #4F768C);
  background: -o-linear-gradient(#435C6F, #4F768C);
  background: -moz-linear-gradient(#435C6F, #4F768C);
  background: linear-gradient(#435C6F, #4F768C);
}

.secondary-grad-bg {
  background: -webkit-linear-gradient(#435C6F, #4F768C);
  background: -o-linear-gradient(#435C6F, #4F768C);
  background: -moz-linear-gradient(#435C6F, #4F768C);
  background: linear-gradient(#435C6F, #4F768C);
  color: white;
}


.pagination-list>span {
  line-height: 33px;
  height: 33px;
  /* width: 1px; */
  /* padding: 0 3px; */
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
}

.next>i {
  margin-right: -2px;
}

.prev>i {
  margin-left: -2px;
}

.icon-space-right {
  margin-right: .3em;
}

.icon-space-left {
  margin-left: .3em;
}

.icon-wrapper {
  position: relative;
  float: left;
  margin-right: .7em;
}


.small-logo {
  height: 60px;
  float: left;
  margin-top: 10px;
}

.small-logo-center {
  height: 60px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.large-logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 7px;
}

.logo-container {
  width: inherit;
  position: absolute;
}

.logon-img {
  box-sizing: border-box;
  height: 350px;
  margin: auto;
  padding-top: 40px;
  display: block;
}

.myaccount {
  width: 100%;
  display: inline-block;
  color: #A6A6A8;
  font-size: 24px;
  text-transform: uppercase;
  padding-top: .7em;
  padding-bottom: .1em;
}

.myaccount span,
.myaccount label {
  font-size: inherit;
  vertical-align: top;
  line-height: 3em;
}

.myaccount span {
  color: #4D4D53;
  padding-left: .5em;
}

label.myaccount-free {
  color: #053057;
}

label.myaccount-paid {
  color: #053057;
}

.logon-img-container {
  box-sizing: border-box;
  width: 60%;
  /*height:449px;*/
  float: left;
}

.logon-title {
  padding-top: 20px;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  color: #435c6f;
}

.header-form button {
  display: block;
  margin-left: auto;
  padding: 2px 15px 2px 15px;
  background-color: #053057;
  margin-top: 17px;
}

.badge-1digit2 {
  position: absolute;
  background: rgb(213, 30, 30);
  height: 1.3em;
  top: -.75em;
  right: -.75em;
  width: 1.3em;
  text-align: center;
  line-height: 1.4em;
  font-size: .75em;
  border-radius: 50%;
  color: white;
  border: 1px solid rgb(213, 30, 30);
}

.badge-1digit {
  position: absolute;
  background: rgb(213, 30, 30);
  height: 1em;
  top: -.75em;
  right: -.75em;
  width: 1em;
  text-align: center;
  line-height: 1em;
  font-size: .75em;
  border-radius: 50%;
  color: white;
  border: .35em solid #d51e1e;
}

.badge-2digit {
  position: absolute;
  background: rgb(213, 30, 30);
  height: 1.4em;
  top: -.75em;
  right: -.75em;
  width: 1.4em;
  text-align: center;
  line-height: 1.4em;
  font-size: .7em;
  border-radius: 50%;
  color: white;
  border: .2em solid #d51e1e;
}

.badge-3digit {
  position: absolute;
  background: rgb(213, 30, 30);
  height: 2em;
  top: -1em;
  right: -1em;
  width: 2em;
  text-align: center;
  line-height: 2.1em;
  font-size: .55em;
  border-radius: 50%;
  color: white;
  border: 1px solid #d51e1e;
}

.hidden {
  display: none;
}

.premium-info-box {
  position: absolute;
  left: 10px;
  bottom: -2.4em;
  background-color: orange;
  color: white;
  text-transform: uppercase;
  padding: .5em;
  padding-bottom: .35em;
  border-radius: 0px 5px 5px 5px;
  font-size: .85em;
  line-height: 1em;
}

.arrow_box {
  border: 1px solid #fff;
}

.arrow_box:after,
.arrow_box:before {
  bottom: 100%;
  left: 7px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(0, 26, 255, 0);
  border-bottom-color: #fda401;
  border-width: 7px;
  margin-left: -7px;
}

.arrow_box:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}

.button-container {
  position: relative;
}

.inactive-btn {
  background-color: #d2d2d3;
  cursor: default;
}

.iframe-container {
  background-color: #efefef;
  height: 100vh;
}

.payment-header {
  font-size: 14px;
  font-weight: 700;
  color: #b2bb1c;
  margin-top: 20px;
  display: block;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.payment-error {
  margin-top: 8px;
  font-size: 13px;
}


/* account type page table */
.account-comparison-container {
  margin-left: -30px;
  margin-right: -30px;
}

table.account-comparison {
  background: transparent;
  border-spacing: 30px 0;
}

.account-comparison td {
  text-align: center;
  border-bottom: 1px solid #a6a6a8;
  height: 70px;
  width: 170px;
}

.account-comparison td:first-child {
  text-align: right;
  border-bottom: none;
  width: 150px;
  font-weight: 500;
}

.account-comparison .header-tab {
  border-radius: 5px 5px 0 0;
  padding: 5px;
  text-transform: uppercase;
  font-size: 1.4em;
  font-weight: normal;
}

.account-comparison i {
  font-size: 2.3em;
}

/* Larger version of fa-lg */
i.hn-fa-lg {
  font-size: 1.5em;
  line-height: .75em;
  vertical-align: -22.5%;
}

.pricing-terms {
  line-height: 2em;
}

.error-message {
  color: #AA0000;
}

input[type="text"],
.login-page input[type="password"] {
  border: 1px solid #c6c6c6;
  border-radius: 3px;
  padding: 0px 0px 0px 5px;
  box-sizing: border-box;
  font-size: 13px;
  margin-right: 0px;
  font-weight: normal;
  display: inline-block;
  line-height: 26px;
  height: 30px;
  vertical-align: initial;
}

select {
  background: #FFF url('../images/select-arrow-black.png') no-repeat right center;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 30px;
}

select::-ms-expand {
  display: none;
}

.change-password-form input[type="text"],
.change-password-form input[type="password"],
.change-password-form select {
  margin-right: 0px;
  width: 300px;
  margin-bottom: 6px;
  vertical-align: initial;
}

.change-password-form input[type="password"] {
  letter-spacing: .1em;
}

.change-password-form label {
  width: 180px;
}


/*** custom checkboxes ***/

/* to hide the checkbox itself */
.data-table input[type=checkbox] {
  display: none;
}

.data-table input[type=checkbox]+label:before {
  display: inline-block;
  font-size: 15px;
  width: 1.3em;
}

.data-table input[type=checkbox]+label:before {
  content: "\f096";
}

.data-table td>input[type=checkbox]+label:before {
  color: black;
}

.data-table input[type=checkbox]:checked+label:before {
  content: "\f046";
}

.blue-grad {
  height: 10.5em;
  background: #b2bb1c;
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(#435C6F, #4F768C);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(#435C6F, #4F768C);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(#435C6F, #4F768C);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(#435C6F, #4F768C);
  /* Standard syntax */
  position: relative;

}

.green-grad {
  height: 10.5em;
  background: #b2bb1c;
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(#b2bb1c, #206020);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(#b2bb1c, #206020);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(#b2bb1c, #206020);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(#b2bb1c, #206020);
  /* Standard syntax */
  position: relative;

}

.green-grad:after {
  top: 95%;
  left: 50%;
  content: url('/src/assets/images/arrow_down.png');
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #206020;
  border-width: 20px;
  margin-left: -20px;
  transform: scale(0.17);
}

.alert-white {
  color: white;
  padding: .66em 0;
  text-align: center;
  font-size: 19.8px;
  font-weight: 700;
}

.info-box {
  border-radius: 1em;
  background: #ebebe0;
  padding-top: .83em;
  width: 40em;
  height: 7.5em;
  font-size: .8em;
  color: #435C6F;
  margin: auto;
  text-align: center;
}

.info-box p {
  font-weight: 300;
  padding-top: .7em;
}

.info-box>img {
  bottom: -0.7em;
  position: relative;
  width: 14em;
}

.info-box-grey {
  background-color: #435c6f;
  padding-top: 1.5em;
  color: white;
  height: 12em;
  text-align: center;
  font-size: 22.5px;
  font-weight: 500;
}

.icon-header {
  font-size: .75em;
  color: white;
  text-align: center;
}

.icon-text {
  font-size: .6em;
  color: white;
  text-align: center;
}

.details-landing h1 {
  font-weight: 400;
  display: inline-block;
  margin-right: 1em;
}

.details-landing {
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0 30px 0;
}

.details-landing button.btn-premium {
  vertical-align: text-bottom;
}

.landing-header {
  width: 100%;
  font-size: 0;
  display: inline-block;
}

.landing-header>img {
  width: 100%;
  height: 100px;
}

.landing-header-main {
  width: 450px;
  height: 373px;
}

.landing-header-line {
  width: calc((100% - 450px)/2);
  height: 373px;
}

.flip-image {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.landing-icons {}

.landing-icons img {
  height: 6em;
}

.landing-icons>div {
  width: calc(352.63/192*6em);
  display: inline-block;
  vertical-align: top;
  margin-top: .5em;
}


/* landing page account type table */
.landing-comparison-container {
  margin-left: -2em;
  /*margin-right: -2em;*/
  font-size: 16.3px;
}

table.landing-comparison {
  background: transparent;
  border-spacing: 2em 0;
  margin-left: auto;
  margin-right: auto;
}

.landing-comparison th {
  vertical-align: bottom;
}

.landing-comparison td {
  text-align: center;
  border-bottom: 2px solid #cfd2d2;
  height: 4em;
  width: 11em;
  padding: .7em;
  font-size: 1em;
}

.landing-comparison td:first-child {
  text-align: right;
  border-bottom: none;
  width: 10.5em;
  font-weight: 500;
}

.landing-comparison td:nth-child(2) {
  background-color: #f3f3f3;
  width: 11em;
}

.landing-comparison td:nth-child(3) {
  background-color: #ffffff;
  width: 13em;
}

.landing-comparison .header-tab {
  border-radius: .36em .36em 0 0;
  padding: .36em;
  text-transform: uppercase;
  font-size: 1.4em;
  font-weight: normal;
}

.landing-comparison .header-tab-larger {
  border-radius: .36em .36em 0 0;
  padding: .6em 0;
  padding-bottom: .36em;
  text-transform: uppercase;
  font-size: 1.4em;
  font-weight: bold;
}


.landing-comparison .fa {
  font-size: 2em;
}

.landing-cell-transparent>td {
  background-color: transparent !important;
  border: 0;
  height: auto;
}

.secondary-gradhr-bg {
  color: #fff;
  background: #fe8c00;
  background: -webkit-linear-gradient(to left, #fe8c00, #f83600);
  background: linear-gradient(to left, #fe8c00, #f83600);
}

.landing-continue {
  font-size: 1.5em;
  font-weight: 500;
  padding: .3em 1em;
  margin-left: auto;
  margin-right: auto;
}

.landing-comparison-validto {
  position: absolute;
  margin-left: -5.3em;
  margin-top: -1.4em;
  font-size: 0.9em;
  font-weight: 700;
}

.landing-freetrial {
  font-size: 0.7em;
  font-weight: 500;
  padding: 0.2em 1em;
  border-radius: 0.3em;
  border: 1px solid white;
  width: auto;
  line-height: 1;
  position: absolute;
  margin-left: -6.9em;
  margin-top: 1.75em;
}

.error-image {
  margin-left: auto;
  margin-right: auto;
  float: none;
}

.error-page-message {
  padding-bottom: 30px;
  width: 100%;
  /* text-align: center; */
  font-size: 18px;
  font-weight: 700;
}

.floating-menu-spacer {
  padding: 0;
  height: 115px;
  margin: 0;
}

.menuclick {
  cursor: pointer;
}

button.submit-btn.secondary-grad-bg.landing-freepremiumtrial {
  font-size: 1.5em;
  font-weight: 500;
  padding: .3em 1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

.header-spacer {
  background-color: #f9f9f9;
  display: block;
  padding-top: 20px;
}

.mandatory label {
  font-weight: bold;
}

.mandatory label::after {
  content: " *";
  color: #BC6262;
}


.ui-widget.error-dialog {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 1.2em;
}

.ui-widget-content.error-dialog {
  background: #FFFFFF;
  border: 1px solid #435C6F;
  color: #222222;

}

.ui-dialog.error-dialog {
  left: 0;
  outline: 0 none;
  padding: 0 !important;
  position: absolute;
  top: 0;
  display: initial;
}

.ui-dialog.error-dialog .ui-dialog-content {
  background: none repeat scroll 0 0 transparent;
  border: 0 none;
  overflow: auto;
  position: relative;
  padding: 0 !important;
  margin: 0;
  text-align: center;

}

.ui-dialog.error-dialog .ui-widget-header {
  background: #435C6F;
  border: 0;
  color: #ffffff;
}

.ui-dialog.error-dialog .ui-dialog-titlebar {
  height: 40px;
  padding: 5px;
  position: relative;
  font-size: 18px;
  overflow: auto;
  letter-spacing: 2px;
}

.ui-dialog.error-dialog .ui-dialog-title {
  margin: 0px;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset .orange {
  font-size: 15px;
  color: #FFFFFF;
  background: #f7941e;
  margin: .5em .4em .5em 0;
  cursor: pointer;
  border-radius: 3px;
}

#errorDialog {
  display: none;
}

.header-section {
  min-width: 1065px;
}

.scripts-container {
  min-width: 1480px;
}

.scripts-filter-box {
  padding: 0;
}

.scripts-logo {
  margin-top: 7px;
}

.footer-container {
  padding: 0;
}

.container {
  padding: 0;
}

.script-caption {
  margin-top: 20px;
}

.width-spacer {
  min-width: 100px;
}

.scripts-footer-box {
  min-width: 1480px;
}

.no-padding-left {
  padding-left: 0px;
}

.settings-component {
  height: calc(100%);
  overflow: auto;
}

.settings-columns-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  height: auto;
  column-gap: 50px;
}

.settings-column {
  flex: 0 1 auto;
  align-content: flex-start;
  min-height: 200px;
  /* least disruptive hack I found to prevent "helpful links" partially overflowing/clipping off the bottom if columns wrapped */
}

.settings {
  background-color: #fff;
  padding: 10px 10px;
  width: 624px;
}

.settings-sidebar {
  /* same style as .settings but without the fixed width */
  background-color: #fff;
  padding: 10px 10px;
}

.script-request-settings {}

.settings-title {
  /* these values are just copying the calender's h1 CSS in style.scss */
  font-weight: bold;
  font-size: 32px;
  font-family: 'Poppins', 'Roboto', 'sans-serif';
  line-height: 45px;
  letter-spacing: 0;
  color: #00217F;
  margin: 0;
  padding: 1px 0 16px 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
}

.settings-handy-link {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  padding: 8px 0 0 25px;

  &.space-top {
    padding-top: 16px;
  }
}

.messsaging-settings {
  margin-top: 40px;
}

.invite-header {
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  background-color: #00217f;
  color: #FFF;
  padding: 10px;
  font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  color: #FFFFFF;
  text-align: left;
}

.invite-header .close-button {
  color: #FFF;
}

.menu-items {
  background-color: #fff;
  width: 100%;
  padding: 0px 5px;
  margin-left: -5px;
}

.sidebar-container {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 5px;
  margin-left: -5px;
  box-shadow: 10px 5px 5px grey;
  height: auto;
}

.router-active {
  background-color: #00217f;
  color: #FFF !important;
}

#editDetails>div>label {
  text-align: right;
  padding-right: 10px;
}

.surgery-summary-header {
  display: flex;
  justify-content: space-between;
}

.close-button {
  cursor: pointer;
  color: #053057;
}

.surgery-summary {
  width: 386px;
  height: 106px;
  background-color: rgba(238, 238, 238, 1);
  margin-top: 10px;
  float: right;
  border-radius: 4px;
}

.surgery-summary .contents {
  padding: 10px;
}

.form-action {
  float: right;
  margin: 10px 0;
}

// Patient Add Form
.date-picker .mat-form-field-label {
  margin-top: 5px;
}

.date-picker .mat-form-field-infix {
  height: 25px;
}

/* Patient Detials */
.patient-details {
  padding: 0 40px;
}

.patient-table {
  padding: 15px;
}

.patient-name {
  font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  padding: 10px 0;
}

.medication-header {
  display: flex;
}

.medication-footer {
  display: flex;
}

.medication-heading {
  font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  text-align: left;
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 10px;
  width: 50%;
}

.medication-column {
  width: 50%;
  margin-left: 50%;
  border-bottom: 1px solid #d5d5d5;
  vertical-align: top;
}

.summary-column {
  width: 55%;
  border-left: 1px solid #d5d5d5;
  padding-left: 20px;
}

#Title {
  margin-top: 20px;
}

.patient-item {
  display: flex;
  align-self: center;
  padding: 10px 0px 0px 0px;
}

.phone-info {
  font-size: 12px;
  margin: 5px 15px;
  align-self: flex-end;
}

.patient-label {
  width: 170px;
  font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  text-align: left;
}

.patient-info {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  text-align: left;
  display: flex;
}

.button-actions {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.button-actions * {
  margin: 0 10px;
}

.heading-table {
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  padding: 10px 0px;
}

.history-table {
  border: 2px solid #e6e6e6;
  border-radius: 5px;
}

.tabel-filter {
  display: flex;
  justify-self: center;
  align-items: center;
}

.tabel-filter * {
  margin: 5px 5px;
}

.tabel-filter select {
  width: 229px;
  border-radius: 15px;
  padding: 0 5px;
  font-size: 14px;
}

/* Page Content */
.validation-messages {
  color: #cb2020;
  font-weight: 400;
  font-size: 13px;
}

/* .pageContent {
  height: max-content;
} */
/* Settings  */

.pharmacy-settings {
  margin-top: 35px;
}

.header-line {
  font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
}

.sub-title {
  font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
}

.main-contents {
  padding: 10px;
  background-color: #e6e6e6;
  border-radius: 4px;
  margin-top: 10px;
}

input[type="number"] {
  width: 43px;
  height: 28px;
  font-size: 14px;
  text-align: center;
}

.label-right {
  margin-left: 5px;
}

.label-field {
  margin-top: 10px;

  .mat-checkbox {
    font-weight: 500;
  }
}

.welcome-messages {
  margin-top: 10px;
}

.hours-noticed {
  margin-top: 10px;
}

.input-ods-code {
  width: 138px !important;
}

.input-phone {
  width: 138px !important;
}

.input-post-city {
  width: 249px !important;
}

.input-post-code {
  width: 120px !important;
}

.btn-update {
  background-color: #053057;
  color: #fff !important;
  width: 107px;
  height: 31px;
  text-transform: uppercase;
  font-weight: 500;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
}

.btn-cancel {
  background-color: #fff;
  color: #053057 !important;
  width: 107px;
  height: 31px;
  text-transform: uppercase;
  font-weight: 500;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
}



.space {
  height: 35px;
}

textarea[type="text"] {
  border: 1px solid #c6c6c6;
  border-radius: 3px;
  font-size: 14px;
  resize: vertical;
  width: 100%;
}

@media screen and (width: 1600px) {
  .header-wrapper {
    min-width: auto;
  }

  .header-box {
    min-height: 620px;
    min-width: auto;
  }

  .footer-box {
    min-width: auto;
  }

  .header-section {
    min-width: 1100px;
  }
}

/* Login Page */
.login-page {
  background-color: #00217f;
  width: 865px;
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-left {
  width: 35%;
}

.layout-left h2 {
  color: #FFF;
  font-weight: 200;
  font-style: normal;
  font-size: 36px;
  color: #FFFFFF;
  text-align: left;
}

.layout-center {
  background-color: #FFF;
  width: 302px;
  height: 421px;
  border-radius: 4px;
}

.img-logo {
  height: 40px;
}

.tittle-message {
  padding: 0 15px;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  white-space: pre-line;
}

.sub-tittle-message {
  padding: 0 15px;
}

.loginpanel {
  padding: 25px;
}

.inputline {
  display: flex;
  align-items: center;
}

.inputline label {
  font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #4e4e53;
}

.btn-login {
  padding: 5px 25px;
  text-transform: capitalize !important;
}

.forgot-password {
  margin-top: 20px;
}

.link-reset-pwd {
  padding: 5px 30px;
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  text-decoration: underline;
  color: #053057;
  text-align: left;
  font-size: 13px;
  cursor: pointer;
}

.disclaimer {
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  text-align: center;
  padding: 25px 0;
}

.login-help {
  width: 145px;
  height: 145px;
  border-radius: 50%;
  background-color: #00973d;
  color: #fff;
  display: table-cell;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  text-align: center;
}

/* moved to new style.scss 
.buttonbar {
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
}
*/
.btn {
  cursor: pointer;
}

.layout-right {
  align-self: flex-end;
  margin: 25px;
}

.login-panel .mat-dialog-container {
  border: 0;
  padding: 0 !important;
  overflow: hidden;
}

.errorMessage {
  color: red;
  padding: 5px 0;
}

.successMessage {
  color: green;
  padding: 5px 0;
}

.a-link {
  text-decoration: underline;
  color: #053057 !important;
}

/* Invite Page */
.invite-page {
  background-color: #d7d7d7;
  padding: 5px 20px;
  height: 435px;
  position: relative;
}

.filter-fields {
  display: flex;
}

.input-row {
  width: 90px;
  margin-right: 25px;
}

.input-box {
  height: 33px;
  padding: 0px 5px;
  width: 100%;
  outline: none;
}

.btn-search-patient {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.btn-search-patient * {
  margin: 0 5px;
}

.error-show {
  color: red;
}

.button-like-link {
  font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: underline;
  color: #053057;
  margin-top: 28px;
  height: 35px;
  margin-left: 2px;
}

.btn-invited {
  float: right;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 30px;
}

.secondary-button {
  font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  background-color: white;
  border: 0;
  border-radius: 4px;
  padding: 5px;
  text-transform: uppercase;
}

.btn-invite {
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  padding: 2px 10px;
}

.btn-invite * {
  margin: 0 2px;
}

.button-invite {
  padding: 12px 12px 2px;
  display: flex;
}

.clear-button {
  text-transform: uppercase;
  font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #004C6C;
  cursor: pointer;
  margin-left: 8px;
}

.clear-button:hover {
  text-decoration: underline;
}

/* List Compoment */

.header-field {
  border-left: 1px solid #FFF;
  font-size: 1em;
  font-weight: bold;
  padding: 0 5px;
  height: 100%;
  vertical-align: middle;
}



.header-home-field {
  border-left: 1px solid #FFF;
  font-size: 1em;
  font-weight: bold;
  height: 55px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  .drugs-header {
    width: 100%;

    tr {
      width: 100%;
      display: grid;
      grid-template-columns: 3fr 1fr;

    }

    .th-1 {
      text-align: left;
      display: flex;
      align-items: center;
    }

    .th-2 {
      text-align: right;
      color: #053057;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.name-header {
  justify-content: left;
}

.header-field:first-of-type {
  border: 0;
}

.btn-search {
  margin: 7px 5px;
  padding: 10px;
}

/* general grid column settings */
.col1 {
  -ms-grid-column: 1;
  grid-column: 1;
}

.col2 {
  -ms-grid-column: 2;
  grid-column: 2;
  padding-left: 10px;
}

.col3 {
  -ms-grid-column: 3;
  grid-column: 3;
}

.col4 {
  -ms-grid-column: 4;
  grid-column: 4;
}

.col5 {
  -ms-grid-column: 5;
  grid-column: 5;
}

.col6 {
  -ms-grid-column: 6;
  grid-column: 6;
}

.col7 {
  -ms-grid-column: 7;
  grid-column: 7;
}

.col8 {
  -ms-grid-column: 8;
  grid-column: 8;
}

.col9 {
  -ms-grid-column: 9;
  grid-column: 9;
}

.col10 {
  -ms-grid-column: 10;
  grid-column: 10;
}

// Surgery Page

.page-surgery-list .gridrow {
  background-color: white;
  align-items: center;
  min-height: 100px;
  display: grid;
  display: -ms-grid;
  border-bottom: solid 1px black;
  grid-template-columns: 1fr 2fr 0.7fr 0.8fr 0.7fr 0.5fr 0.8fr !important;
}

.page-surgery-list .gridrow:last-child {
  margin-bottom: 60px;
}

.gridwrapper {
  width: 100%;
  height: 100%;
}

.gridPane {
  max-height: 100%;
  height: 100%;
}

/*
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    IE specific handling
    .gridPane {
        max-height: 100%;
        height: 100%;
    }

 }
*/
#requestGrid .gridPane {
  height: 10px;
}

/*
#requestGrid {
	width:100%;
	height:100%;
}
*/
#grid1wrap {
  width: 100%;
  height: 100%;
}

.inverse-btn {
  min-width: 50px;
  outline: none;
}

.submit-btn[disabled] {
  color: #FFB483;
  cursor: default;
}

.btn-add-new-gp {
  width: 201px;
  background-color: #053057;
  color: #fff;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
}

#addSurgeryNavigationButton span {
  position: absolute;
  width: 100%;
  left: 0;
  top: 8px;
  z-index: 999;
}

#addSurgeryNavigationButton span:hover {
  text-decoration: underline;
}

.btn-add-new-gp a {
  color: #fff;
}

select {
  border: 1px solid #c6c6c6;
  border-radius: 3px;
  padding: 2px 8px 0px 8px;
  box-sizing: border-box;
  font-size: 14px;
  margin-right: 0px;
  font-weight: normal;
  display: inline-block;
  width: 100%;
  line-height: 26px;
  height: 30px;
  vertical-align: initial;
}

.summary {
  font-size: smaller;
  line-height: 15px;
}

.filter-label {
  margin: 0;
}

.filter-column {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
}

.filter-form {
  padding-left: 15px;
  padding-right: 15px;
}

.main-grid-panel {
  height: calc(100vh - 240px);
  /*padding-bottom: 60px;*/
}

app-gprequests {
  display: relative;
  height: 100%;
}

.messagerow {
  overflow-y: clip;
  align-items: center;
  white-space: pre-line;

  .date-time-field {
    width: 100%;
    text-align: center;
  }
}

.filter-home-item {
  width: 200px;
  margin-left: 15px;
  border: 1px solid #053057 !important;
}

/* Inbox  */
.info-name-message .only-show-on-hover {
  visibility: hidden;
}

.info-name-message:hover .only-show-on-hover {
  visibility: visible;
}

.drugs-infomation .only-show-on-drugs-hover {
  visibility: hidden;
}

.drugs-infomation:hover .only-show-on-drugs-hover {
  visibility: visible;
}

.overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sortOrder {
  display: flex;
  flex-direction: column;
  align-items: center;

  .sort-icon {
    margin-bottom: -10px;
    cursor: pointer;
  }

  .sort-icon-desc {
    margin-top: -10px;
    cursor: pointer;
  }
}

.sortOrder input[type=radio] {
  visibility: hidden;
  display: none;
}

.sortOrder input[type=checkbox] {
  visibility: hidden;
  display: none;
}

.mat-spinner circle {
  stroke: #7896A4 !important;
}

.drugs-additional {
  color: #053057;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  font-size: 14px;
}

.only-show-on-hover {
  position: absolute;
  left: 150px;
  top: 35px;

  background-color: #fff;
  padding: 3px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  z-index: 1;
}

.only-show-on-drugs-hover {
  position: absolute;
  left: 30%;
  top: 35px;
  color: #4e4e53;
  font-weight: 400;
  font-size: 14px;
  background-color: #fff;
  padding: 3px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  z-index: 1;
}

.info-name-message {
  margin-bottom: 0;
}

.dialog-name {
  font-weight: 700;
  margin-bottom: 3px;
  cursor: pointer;
  color: #053057;
}

.dialog-item {
  margin-bottom: 3px;
}

.btn-sweet-confirm {
  background-color: #053057 !important;
}

.amber-color {
  color: #D49900;

}

.loading-line {
  border-bottom: 1px solid #4e4e53;
}

.send-message-button {
  margin-left: 24px;
}

.last-dispense-details {
  width: 100%;
  text-align: center;
}

.medicationrow .actions {
  padding: 0px 10px;
  width: 100%;
}

.message-headers {
  background-color: #E5E5E5;
  font-weight: bold;
  padding: 0px;
  align-items: center;
  height: 45px;
  border-bottom: solid 1px white !important;
}

.message-row {
  align-items: center;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2fr 6fr 2fr 2fr 3fr;
  border-bottom: 1px solid #000;
  grid-template-columns: 2fr 6fr 2fr 2fr 3fr;
  padding: 5px 0;
  position: relative;
}

.message-row:last-child {
  margin-bottom: 60px;
}

div.message-row-desktop-deleted {
  animation: removed-item-animation .6s cubic-bezier(.55, -0.04, .91, .94) forwards;
}

@keyframes removed-item-animation {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
    height: 0;
    padding: 0;
    margin: 0;
  }
}

.right-header {
  display: flex;
}

.order-no {
  margin: 3px 10px;
}

.loading-row {
  background-color: white;
  align-items: center;
  height: 100px;
  min-height: 100px;
  border-bottom: 1px solid #000;
  display: grid;
  display: -ms-grid;
}

.surgeryrow {
  padding-left: 12px;
  padding-right: 5px;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  min-height: 100px;
}

.filters {
  background-color: #E5E5E5;
  padding: 5px;
  font-weight: bold;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  height: 60px;
}

.page-details {
  border-top: solid 2px #053057;
  width: 99.9%; /* this stops the horizonal scrolbar when appearing in full screen */
}

.search-surgery-list .gridrow {
  background-color: white;
  align-items: baseline;
  min-height: 100px;
  display: grid;
  display: -ms-grid;
  grid-template-columns: 2fr 2fr 1fr;
  -ms-grid-columns: 2fr 2fr 1fr;
  margin-bottom: 20px;
}


.search-surgery-list .contact-details {
  font-size: 13px;
  box-shadow: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  line-height: 24px;
}

.page-surgery-list .headers div {
  padding: 12px 5px 0px 12px;
  height: 61px;
  /* border-left:solid 1px lightgrey; /* Used for testing column alignment */
}

.page-surgery-list .headers div:last-child {
  padding-left: 25px;
}


.page-surgery-list .gridrow.headers {
  background-color: #E5E5E5;
  font-weight: bold;
  padding: 0px;
  align-items: center;
  max-height: 68px;
  height: 68px;
  min-height: 68px;
  border-bottom: solid 1px white;
  text-decoration: underline;
  font-size: 14px;
  width: 100%;

}

.input-wrapper {
  border-left: none !important;
}

.gp-overdue {
  display: flex;
  align-items: baseline;
  margin: 10px 0 20px;
}

.filter-header {
  height: 20px;
  padding: 1px 2px 3px 2px;
  font-size: 13px;
  width: 167px;
  text-align: left;
  line-height: 17px;
  margin-top: 5px;
  margin-left: -5px;
}

cdk-virtual-scroll-viewport {
  height: calc(98% - 20px);
  /* border:solid 1px grey; */
  margin-left: 3px;
  margin-right: -1px;
}

/* .cdk-virtual-scroll-content-wrapper {
    overflow-y: scroll;
} */
.cdk-virtual-scroll-content-wrapper {
  contain: none !important;
}

.welcome {
  margin-left: 15px;
  font-size: 1.5em;
  line-height: 2em;
}

.summary {
  margin-left: 15px;
}

.page-surgery-list {
  border-top: none !important;
  font-size: 13px;
}

.page-nav input[type="radio"] {
  visibility: hidden;
  display: none;
}

.page-nav label {
  border-top: solid 2px #053057;
  border-left: solid 1px #053057;
  border-right: solid 1px #053057;
  width: min-content;
  padding: 3px 30px 3px 30px;
  background-color: white;
  color: #053057;
  text-transform: uppercase;
  font-size: 1.0em;
  font-weight: bold;
  margin-bottom: 0px;
}

.page-nav label.left {
  border-left: solid 2px #053057;
  border-top-left-radius: 10px;
}

.page-nav label.right {
  border-right: solid 2px #053057;
  border-top-right-radius: 10px;
}

.page-nav input[type="radio"]:checked+label {
  background-color: #00217f;
  color: white;
  text-decoration: underline;
}

.sorting-date {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.align-center {
  text-align: center;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #333;
}

.messagerow-info {
  font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto', sans-serif;
  font-size: 13px;
  margin-left: 5px;
}

.info-name {
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 10px;
  cursor: pointer;
  color: #053057;
}

.info-name-text {
  font-weight: 700;
  margin-bottom: 10px;
  color: #053057;
}

.info-item {
  margin-bottom: 10px;
}

.button-default {
  width: 145px;
  text-transform: uppercase;
  border-right: 1px solid #fff;
  font-size: 14px;
}

.action-input {
  width: 155px !important;
}

.sorting-date div {
  display: flex;
  align-items: center;
}

.messagerow.actions {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.page-surgery-list {
  margin: 10px 0;
  height: calc(100% - 15px);
  width: 99.9%; /* this stops the horizonal scrolbar when appearing in full screen */
}

.action {
  background-color: #053057;
  color: white;
  border-radius: 5px;
  text-align: center;
  margin: 3px;
  flex: 0 0 1;
  cursor: pointer;
}

.surgeryrow.actions {
  display: flex;
  text-align: center;
  justify-content: center;
}

.button-update {
  height: 36px;
  background: inherit;
  background-color: rgba(0, 76, 108, 1);
  border: none;
  border-radius: 4px;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
  width: 110px;
}

.add-icon {
  float: left;
}

.last-updated {
  text-align: right;
}

.search-form {
  display: flex;
  align-items: baseline;
}

.add-new-gp-workflow {
  margin-top: 40px;
  margin-left: 5px;
  width: 40%;
  min-width: 768px;
}

.search-buttons {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.search-buttons * {
  margin: 0 5px;
}

.GP-search-label {
  font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto', sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #333333;
}

.GP-search-input {
  margin-left: 25px;
}

.gp-search {
  width: 342px;
  height: 30px;
}

.overdue-timer {
  display: flex;
}

.input-overdue {
  width: 48px !important;
}

.hour-des {
  margin: 10px;
  font-size: 14px;
}

.blue-line {
  height: 3px;
  background-color: #009cdc;
  width: 100%;
  margin-top: 10px;
}


.search-result {
  margin-top: 15px;
}

.note-from-patient {
  padding: 10px 0;
}

.grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-shadow: none;
  font-size: 13px;
  padding: 20px 0;
}

.surgery-form {
  width: 272px;
  height: 30px;
  padding: 3px 2px 3px 2px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  color: #000000;
  vertical-align: none;
  text-align: left;
  text-transform: none;
  background-color: transparent;
  border-color: transparent;
  border: 1px solid #c6c6c6;
}

.surgery-form-details {
  padding: 19px 24px
}

.medication-table .cdk-virtual-scroll-viewport {
  min-height: 170px;
}

.mat-button-toggle-appearance-standard {
  background-color: #04529c;
  color: #fff;
}

.mat-button-toggle[Disabled] {
  background-color: grey;
  color: #fff;
}

.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background-color: grey;
  color: #fff;

}

.mat-button-toggle-checked {
  background-color: #04529c;
  color: #fff !important;
}

.mat-mdc-menu-content {
  background-color: #04529c;
}

.mat-mdc-menu-item {
  padding: 0 5px !important;
  border: 1px solid #fff !important;
}
.mdc-list-item__primary-text {
  color: #fff !important;
  text-transform: uppercase;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 43px !important;
  padding: 0 5px !important;
  text-transform: uppercase;
}

.mat-menu-panel {
  margin-right: 35px;
  border-radius: 0;
  width: 160px;
  min-height: unset;
}

.mat-menu-content:not(:empty) {
  padding: 0 !important;
}

.mat-tooltip.tooltip-show {
  font-size: 11px;
  width: 100%;
}

.tooltip-show {
  background-color: #fff;
  color: #000 !important;
}

.overdue-color {
  color: red;
}

.status-pending-task {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0;
  padding-left: 20px;
}

.status-pending-task * {
  margin: 0 3px;
}

.message-status-type {
  text-transform: uppercase;
}

.message-type-icon {
  font-size: 16px;
}

@media only screen and (max-width: 1024px) {
  .message-row {
    -ms-grid-columns: 2fr 6fr 2fr 2fr 3fr;
    grid-template-columns: 2fr 6fr 2fr 2fr 3fr;
  }

  .scr-detail {
    max-height: 250px;
  }
}

@media only screen and (max-width: 1440px) {
  .message-row {
    -ms-grid-columns: 2fr 6fr 2fr 2fr 3fr;
    grid-template-columns: 2fr 6fr 2fr 2fr 3fr;
  }
}


@media screen and (width: 1280px) {
  .header-wrapper {
    min-width: auto;
  }

  .header-box {
    min-height: 420px;
    min-width: auto;
  }

  .footer-box {
    min-width: auto;
  }

  .script-caption {
    margin-top: 0px;
  }

  .header-section {
    min-width: 1080px;
  }
}

@media screen and (width: 1360px) {
  .header-wrapper {
    min-width: auto;
  }

  .header-box {
    min-height: 420px;
    min-width: auto;
  }

  .footer-box {
    min-width: auto;
  }

  .script-caption {
    margin-top: 0px;
  }

  .scripts-header-container {
    min-height: 420px;
  }

  .header-section {
    min-width: 1100px;
  }

  .width-spacer {
    min-width: 0px;
  }

  .scripts-footer-box {
    min-width: 1480px;
  }

}

@media screen and (min-width: 1400px) {
  .header-section {
    min-width: 1100px;
  }

  .scripts-container {
    min-height: 700px;
    min-width: auto;
  }

  .scripts-filter-box {
    min-width: auto;
  }

  .header-section {
    min-width: 1060px;
  }

  .scripts-footer-box {
    min-width: 1480px;
  }
}

@media screen and (width: 1920px) {
  .header-section {
    min-width: 1200px;
  }
}


@media screen and (min-width: 1201px) {
  .sidebar-icon {
    display: none;
  }

  div#wrapper {
    display: flex;
  }

  .filter-item {
    padding: 0px 10px 0px 0px;
  }

  .img-logo {
    height: 50px;
  }

  .sidebar-container {
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 5px;
    box-shadow: none;
    height: inherit;
  }
}

@media screen and (min-height: 1080px) {
  .main-grid-panel {
    height: calc(72vh) !important;
  }
}

@media screen and (min-height: 1024px) {
  .main-grid-panel {
    height: calc(66vh);
  }
}

@media screen and (max-width: 768px) {
  .hide-on-small-screen {
    display: none;
  }

  .login-page {
    width: unset;
    padding: 10px 10px;
  }

  div#wrapper {
    display: block;
    float: none;
  }

  .messagerow {
    font-size: 13px;
  }

  .messagerow-info {
    padding: 0;
    margin: 0 10px;
  }

  .manage-button {
    width: 70px !important;
  }

  .logout-button {
    position: relative;
    width: 35px;
  }

  .logout-top {
    position: absolute;
    right: 0;
    margin: 18px;
    text-transform: uppercase;
    font-size: 13px;
    vertical-align: top;
    line-height: 24px;
  }

  .invite {
    display: none;
  }

  .patient-list .gridrow {
    align-items: flex-start;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
  }

  .gridrow.headers.withfilters {
    font-size: 13px;
  }

  .filter-item {
    padding: 3px;
  }

  .btn-search-patient {
    margin-top: 11px !important;
    flex-direction: column;
  }

  .button-like-link {
    margin-top: 5px !important;
  }

  .add-new-gp-workflow {
    width: unset !important;
  }
}

@media screen and (max-width: 1200px) {
  .material-icons-ex {
    padding-top: 5px;
    padding-right: 5px;
  }

  .sidebar {
    display: none;
  }

  .login-page {
    width: unset;
    padding: 10px 10px;
  }

  div#wrapper {
    display: block;
    float: none;
  }

  .sidebar.responsive {
    display: block;
    position: absolute;
    z-index: 999;
    top: 59px;
    background: none;
    -webkit-animation: slideOut 0.33s ease-in forwards;
    animation: slideOut 0.3s ease-in forwards;

    .logout-button {
      position: relative;
      width: 35px;
    }

    .btn-search-patient {
      margin-top: 11px !important;
      flex-direction: column;
    }

    .desktop-brand {
      display: none;
    }
  }
}

.non-show-reload-btn {
  justify-content: flex-start;
}

.m-l-0 {
  margin-left: 0px;
}

//Patient invite page
.patient-add-search-row {
  border-bottom: 2px solid #cfcfcf;
  width: 99%;
  margin: auto;

  .patient-add-search-row-content {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .space-bottom {
    margin-bottom: 10px;
  }
}

.toggle-button-cover {
  min-width: 200px;
  display: table-cell;
  position: relative;
  width: 200px;
  height: 90px;
  box-sizing: border-box;
}

.button-cover {
  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 130px;
  height: 35px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 35px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #00217F;
  transition: 0.3s ease all;
  z-index: 1;
}

#button-10 .knobs:before,
#button-10 .knobs:after,
#button-10 .knobs span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 65px;
  height: 35px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  box-sizing: border-box;
  border-radius: 2px;
  transition: 0.3s ease all;
}

#button-10 .knobs:before {
  content: '';
  left: 0px;
  background-color: #fff;
  border: 2px solid #00217F;
  border-radius: 45px;
}

#button-10 .knobs:after {
  content: 'NAME';
  right: 0px;
  color: #fff;
}

#button-10 .knobs span {
  display: flex;
  color: #00217F;
  z-index: 1;
}

#button-10 .checkbox:checked+.knobs span {
  color: #fff;
}

#button-10 .checkbox:checked+.knobs:before {
  left: 65px;
  border: 2px solid #00217F;
  background-color: #fff;
}

#button-10 .checkbox:checked+.knobs:after {
  color: #00217F;
}

#button-10 .checkbox:checked~.layer {
  background-color: #00217F;
}

.patient-add-input-field {
  width: 150px;
  margin-right: 25px;
}

.sex {
  margin-top: 21px;
}

.nhs-toggle {
  border-right: 3px solid #009CDC;
  height: 100%;
  margin-right: 30px;
}

.view-wrapper {
  border: 1px solid #A8A8A8;
  border-radius: 25px;
  padding: 15px;
  margin: 20px;
}

.verifedconsent {
  height: 100%;
}

.search-pds-title {
  color: #009CDC;
}

.datepicker-error {
  min-width: 140px;
  margin-top: 18px;
}

.message-alert-danger {
  color: #f44336;
  font-size: 11px;
  position: absolute;
}

.patient-add-input-row {
  width: 100px;
  margin-right: 25px;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: #fff !important;
}

/* Code witch overwrites input background-color */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  box-shadow: 0 0 0px 1000px #ffffff inset;
}

.patient-add-input-box {
  border: 1px solid;
  height: 37px;
  font-size: 14px;
  color: black;
  padding: 0px 5px;
  width: 100%;
  outline: none;
}

.patient-add-picker {
  width: 140px;
}

.patient-add-nhs-input {
  padding-bottom: 1.125rem;
}

.patient-add-input-wrapper {
  width: 120px;
}

.patient-add-input {
  border: 1px solid black;
  height: 33px;
  color: #053057;
  border: 0;
}

.patient-add-search-action {
  display: flex;
  align-items: center;
  width: 255px;
  justify-content: space-around;
}

mat-form-field:not(.mat-form-field-should-float) .mat-input-placeholder {
  padding-left: 10px;
}

.no-result-found {
  color: #AA0000;
  font-weight: bold;
}

.patient-add-field-control {
  margin-right: 15px;
  padding-top: 15px;
}

.date-of-birth-input {
  width: 135px;

  mat-form-field {
    // .mat-form-field-infix {
    //   border: 1px solid;
    //   border-color: -internal-light-dark(rgb(118, 118, 118), rgb(195, 195, 195));
    // }

    // .mat-form-field-underline {
    //   display: none;
    // }
  }

  mat-datepicker-toggle {
    .mat-datepicker-toggle-default-icon {
      font-size: 30px;
      vertical-align: unset;
    }
  }
}

.invite-page-patient-add {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-page {
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-text {
      font-size: large;
      font-weight: 700;
      margin-bottom: 24px;
    }

    .fa-3x {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.result-empty-default {
  text-align: center;
  margin: auto;
  width: 50%;
  padding: 50px;
  font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
  font-weight: 700;
  font-style: normal;
  color: #AA0000
}

.result-empty {
  text-align: center;
  margin: auto;
  width: 50%;
  padding: 50px;
  font-weight: 700;
}

.patient-add-date-picker {
  width: 125px;
}

.patient-add-search-btn {
  height: 35px
}

.patient-add-button-like-link-clear {
  display: flex;
  align-items: center;
  font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  color: #053057;
  height: 35px;
  cursor: pointer;
  margin-left: 25px;
}

.mat-success {
  background-color: #009CDC;
  color: #fff;
}

.date-picker input.mat-input-element {
  color: black;
  font-family: 'Roboto';
  caret-color: #000 !important;
  margin-top: 0 !important;
  width: calc(100% - 5px);
  height: 25px;
  padding-left: 5px;
}

.date-picker-placeholder {
  color: #a9a9a9;
  margin-left: 5px;
  font-family: 'Roboto';
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 1;
  /* Firefox */
}

.patient-add-picker .mat-form-field-infix span {
  padding-left: 5px !important;
}

.patient-add-picker.mat-focused .mat-form-field-label {
  color: rgba(0, 0, 0, .54) !important;
}

.mat-form-field-invalid .mat-input-element,
.mat-warn .mat-input-element {
  caret-color: rgba(0, 0, 0, .54) !important;
}

.patient-add-heading {
  margin-left: 20px;
  font-family: "Roboto Bold", "Roboto Regular", Roboto;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
}

.controls-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  flex-wrap: wrap;
  width: 100%;
}

.invite-dob {
  max-width: 201px;
}

.patient-add-barcode {
  width: 370px;
  position: relative;
  top: -6px;
  height: 100%;
  margin-right: 30px;
}

.heading-invite {
  height: 95%;
}

.barcode-search-button {
  margin-right: 30px;
}

.checkbox-invalid {
  margin-left: 155px;
  color: #AA0000;
  font-size: 12px;
  font-weight: 700;
}

.sidebar .menu-item i.material-icons {
  display: none;
}

.menu-items ul {
  padding-left: 0px;
  margin-block-start: 7px;
}

.invite-patient-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button {
    background-color: #052F57;
    color: #fff;
  }

  button[disabled] {
    background-color: gray;
  }
}

.mobile-number-note {
  font-size: 11px;
  margin-left: 15px;
}

.patient-checkbox {
  display: flex;
  align-items: center;
}

.patient-checkbox .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}

//End of Patient invite

//Submit patient invite
.invited-form {
  border-top: 3px solid #009cdc;
  padding-bottom: 20px;
  width: 99%;
}

.invite-text-green {
  color: green;
}

.invite-row-space {
  margin-top: 15px;
}

.left-first-row {
  margin-left: 30px;
}

.left-second-row {
  margin-left: 50px;
}

.send-invite-btn {
  margin: 15px 0 !important;
  padding: 5px 10px !important;

  .material-icons {
    margin-top: -5px;
  }
}

.patient-sent {
  font-size: 12px;
  color: #AA0000;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 10px;
}

.field-required {
  font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
  font-weight: 700;
  font-style: normal;
  color: #AA0000;
}

.phone-info-page {
  font-size: 12px;
  padding-top: 10px;
  padding-left: 20px;
  margin-left: 15px;
}

.example-success {
  border-radius: 15px;
  border-top-right-radius: 0px;
  background-color: #d7d7d7;
  font-size: 0.85em;
  color: #000;
  padding: 12px;
  max-width: 300px;
}

.message-sent-success {
  border-radius: 15px;
  border-top-right-radius: 0px;
  background-color: #009CDC;
  font-size: 0.85em;
  color: #fff;
  padding: 12px;
  max-width: 300px;
}

.patient-add-component {
  height: calc(100% - 50px);
  overflow: auto;
  margin-top: 5px;
}

.patient-detail {}

@media screen and (min-width: 1440px) {
  .patient-detail {
    .summary-scr {
      .scr-detail {
        max-height: 380px;
      }
    }
  }

}

@media screen and (max-width: 1024px) {
  .patient-detail {
    display: block;

    .patient-consent {
      display: block;
      width: 100%;
      margin-bottom: 20px;
    }

    .summary-scr {
      display: block;
      width: 100%;
    }
  }
}


.date-picker .mat-datepicker-toggle-default-icon {
  margin-top: -10px;
}

.non-show-reload-btn {
  justify-content: flex-start;
}

.m-l-0 {
  margin-left: 0px;
}

.error-width {
  min-width: 190px;
}

.drugs-container {
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.drugs-left {
  width: 50%;
  border-bottom: 1px solid #d5d5d5;
}

.drugs-right {
  width: 50%;
  height: 100%;
}

//DrugUpdateDialog
.drug-dialog-title {
  margin: 10px 10px 20px 15px;
  font-size: 18px;
  color: #009CDC;
  display: flex;
  justify-content: space-between;
}

.close-dialog-icon {
  color: #000;
  border: 1px solid #000;
  font-size: 20px;
  cursor: pointer;
}

.or-text {
  color: #009CDC;
  font-weight: 700;
  font-size: 18px;
  margin-left: 50px;
}

.search-button {
  background-color: rgb(5, 47, 87);
  color: #fff;
  height: 30px;
  margin-left: 50px;
  border-radius: 5px;
  border: none;
}

.drug-row {
  padding-bottom: 10px;
  border-bottom: 1px solid #d5d5d5;
}

.drug-name {
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
}

.drug-edit-dialog-row {
  display: flex;
}

.drug-edit-dialog-wrapper {
  display: grid;
  grid-template-columns: 200px auto;
  grid-template-rows: 1fr;
  grid-template-areas:
    "title-1 field-1"
    "title-2 field-2"
    "title-3 field-3"
    "title-4 field-4";
}

.drug-edit-divider {
  display: block;
  height: 1px;
  border-bottom: 1px solid #d5d5d5;
  padding-top: 5px;
}

.field-2 {
  width: 330px;
  display: flex;
  align-items: baseline;
}

.field-2-select {
  width: 40%;
}

.field-4 {
  display: flex;
  align-items: center;
}

.drug-dialog-days-treatment {
  display: flex;
  align-items: center;
  width: 18%;
}

.drug-dialog-days-treatment-input {
  height: 26px !important;
  border: 1px solid #c6c6c6;
  border-radius: 3px;
}

.supply-days-left-label {
  padding-left: 12px;
}

.search-all-text {
  width: 630px;
  outline: none;
}

.link-clear {
  color: #052f57;
  font-size: 15px;
  margin-left: 15px;
  cursor: pointer;
}

.drug-seach-panel {
  padding-bottom: 10px;

  .first-row {
    margin-bottom: 10px;
  }
}

.search-no-results {
  display: flex;
  justify-content: center;
  align-items: center;
}

.font-size-11 {
  font-size: 11px;
}

.spinner-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

//END Patient invite NPA

.patient-consent {
  .patient-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 75%;

    .gp-label {
      font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto';
      font-weight: 500;
      font-weight: bold;
      font-size: 14px;
      text-align: left;
      margin-right: 10px;
    }

    .patient-label {
      width: 40px;
      font-weight: bold;
    }

    .phone-warning-mess {
      font-size: 12px;
      padding: 5px 0;
    }

    .info-icon {
      font-size: 18px;
      margin-left: 10px;
      color: #053057;
    }

    .mobile-number-placed {
      color: #053057;
      padding-top: 8px;
      text-decoration: underline;
      font-weight: bold;
    }

    .patient-label-expand {
      width: 85px;
      font-family: "Roboto Medium", "Roboto Regular", "Roboto";
      font-weight: bold;
      font-style: normal;
      font-size: 14px;
      text-align: left;
      display: inline-flex;

      .material-icons {
        font-size: 18px;
        color: #053057;
      }
    }

    .patient-address-spanning {
      grid-column: span 4;
    }
  }
}

.sidebar {
  position: relative;
}

.profile-avatar {
  //  display: block;
  display: none;

  box-sizing: border-box;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  min-height: 85px;
  bottom: 0px;
  position: absolute;
}

.qxlva-card-check {
  padding-left: 10px;
  max-width: 700px;
}

.card-required-warning {
  //  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  color: red;
  height: 20px;
  position: relative;
  top: -10px;

  .material-icons {
    bottom: -7px;
    position: relative;
  }
}

.add-medication-btn {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 6px 10px;
}

.edit-drug-list {
  width: 50%;
  margin-top: 20px;
}

.retrieve-scr {
  width: 50%;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.add-medication-btn * {
  margin: 0 5px;
}

.medication-title {
  margin-left: 50%;
}

.medication-info {
  margin-top: 32px;
  margin-bottom: 35px;
  font-size: large;
  font-family: 'Roboto';
  text-align: center;

}

.invite-confirm {
  padding: 0 40px;
}

.grid-layout-invite {
  display: grid;
  grid-template-columns: 5fr 4fr;
}

.grid-layout-invite-consent {
  display: grid;
  grid-template-columns: 1fr 10fr;
}

.invite-consent-checkbox {
  margin-top: 40px;
  margin: auto;
}

.grid-col {
  position: relative;
}

.mobile-edit-panel {
  display: flex;
  flex-direction: column;
  padding: 5px 5px;
  z-index: 1000;
  background-color: #fff;

  .mobile-edit-row {
    display: flex;

  }

  .phone-warning-dialog {
    font-size: 12px;
  }

  .btn-mobile-update {
    margin-left: 5px;
  }
}

.display-example {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-top-img {
  padding: 5px 5px;
  color: #00973d;
}

.height-400 {
  min-height: 400px;
}

.error-message-scr {
  font-size: 18px;
  padding: 15px 0px;
}

.warning-message {
  font-size: 12px;
  color: #AA0000;
  margin-top: 10px;
  max-width: 700px;
}

.search-all-content {
  display: flex;
  align-items: center;
}

.no-brand {
  color: #A6AEB4;
}

.edit-mobile {
  cursor: pointer;
}

//Carer mode
.carer-mode-instructions {
  border: 1px solid #39B2E4;
  border-radius: 25px;
  padding: 12px;
  margin: 20px 20px 20px 20px;

  .instructions-header {
    display: flex;
    align-items: center;

    .instruction-header-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.6;
    }
  }
}

.patient-list-container {
  .patient-active {
    .col2 {
      min-width: 92px;
    }

    .search-field {
      padding: 10px;
      min-width: 142px;
      margin-top: 15px;
      padding-right: 35px;
    }

    border: 2px solid #666666;
    border-radius: 10px;

    .patient-list {
      margin-top: 10px;

      .gridrow {
        grid-template-columns: 4fr 2fr 2fr 2fr 2fr 1fr;
        border-radius: 5px;
      }
      .gridrow:last-child {
        margin-bottom: 60px;
      }
    }
  }

  .cdk-virtual-scroll-viewport {
    height: calc(100% - 100px);
  }

  .patientrow {
    padding: 0 20px;
  }

  .patient-list-des {
    font-weight: bold;
    border-bottom: 1px solid grey;
  }

  .paient-grid-layout {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .patient-name-col {
      font-size: 13px;

      * {
        margin: 3px 0;
      }

      .patient-name-item {
        display: flex;

        .patient-name-bold {
          font-weight: bold;
          color: #053057;
          text-decoration: underline;
          cursor: pointer;
        }

        .carer-mode {
          margin-left: 2px;
          font-weight: normal;
        }
      }
    }

    .patient-role-icon {
      margin-right: 15px;
    }
  }
}

.patient-panel {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .chat-box {
    position: relative;
    top: -5%;

    .message-inbox-body {
      max-height: 300px;
      min-height: 275px;

      .no-message {
        color: grey;
      }
    }

    .minor-title {
      font-weight: bold;
    }

    .btn-close-message-inbox {
      display: none;
    }
  }
}
.im1LinkageKeyContainer {
  padding-right: 10px;
}
.message-inbox-title {
  padding: 10px;
}

// Patient details - NPA
.pageContent-patient {
  height: 100%;
  background-color: #FFF;
  margin-left: -14px;
  margin-top: -35px;
  padding-top: 30px;

  .border-bottom {
    border-bottom: 1px solid #4e4e53;
  }
}

.patient-content {
  background-color: #FFF;
}

.patient-details-section {
  margin: 0px 30px;
  padding-bottom: 12px;

  .patient-name-title {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
  }

  .patient-information-panel {
    padding: 10px 0px 0px 0px;
  }

  .button-panel {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }

  .remove-patient {
    display: flex;
    align-items: center;
    padding: 5px 10px;
  }

  .warning-delete {
    font-style: italic;
    font-size: 12px;
    float: left;
    margin-top: 7px;
    margin-left: 5px;
  }

  .remove-disabled {
    background-color: #797979;
    color: #FAFAFA;
  }

  .caring-panel {
    margin-left: 20px;
  }
}

.edit-role {
  padding-bottom: 40px;
  margin: 0px 30px;

  .standard-patient {
    border: none !important;
  }

  .first-row {
    height: 60px;
  }

  .root-panel {
    float: left;
    display: flex;
    align-items: center;
    padding: 13px 10px;
    margin-top: -12px;
    background-color: #E6E6E6;
    border-radius: 10px;
    margin-left: 10px;
    width: 75%;
    min-width: 600px;
  }

  .leaf-panel {
    float: left;
    display: flex;
    align-items: center;
    padding: 13px 10px;
    margin-top: -16px;
    background-color: #F2F2F2;
    border-radius: 10px;
    margin-left: 10px;
    width: 525px;

    .col-1 {
      width: 35%;
    }

    .col-2 {
      width: 25%;
    }

    .col-3 {
      width: 20%;
    }

    .col-4 {
      width: 20%;
    }
  }

  .main-tree {
    position: absolute;
    top: -25px;
    left: -10px;
    width: 100%;
  }

  .people-icon {
    float: left;
    color: #009CDC;
  }

  .people-icon-outline {
    color: #333333;
  }

  .title-role {
    float: left;
    margin-top: 5px;
    margin-left: 5px;
    font-weight: bold;
    color: #009CDC;
    margin-bottom: 20px
  }

  .grid-layout-invite-caree {
    display: grid;
    grid-template-columns: 35% 20% 45%;
    width: 100%;
    align-items: center;

    div {
      padding-left: 10px;
    }
  }

  .title-leaf {
    float: left;
    margin-top: 5px;
    margin-left: 5px;
    font-weight: bold;
    color: #333333;
  }

  .tree-leaf-title {
    font-weight: bold;
    color: #797979;
  }

  .title-tree {
    position: absolute;
    left: 40px;
    top: -8px;
    color: #797979;

    .title-tree-name {
      color: #004C74;
    }
  }

  .patient-caree {
    color: #052F57;
  }

  .link-icon {
    color: #052F57;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  li {
    list-style-type: none;
    border-left: 1px solid #000;
    margin-left: 1em;
    position: relative;
  }

  li .root {
    padding-left: 40px;
    position: relative;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  li .root::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0px;
    width: 30px;
    border: 1px solid #000;
    border-top: 0 none transparent;
    border-right: 0 none transparent;
  }

  ul>li:last-child {
    border-left: 1px solid transparent;
  }

  .drug-list-panel {
    width: 100%;
    margin: 20px 0px 20px 80px;
  }

  .drug-list-item {
    width: 100%;
    margin-top: 10px;
  }
}

.patient-details-heading {
  color: #009CDC;
  font-size: 26px;
  margin-top: 12px;
}

.edit-icon {
  font-size: 18px;
  cursor: pointer;
  color: #052F57;
  width: 20px;
  height: 20px;
}

.edit-role-link {
  font-weight: bold;
  margin-left: 4px;
  text-decoration: underline;
  cursor: pointer;
}

.pullleft {
  float: left;
}

.align-center-div {
  display: flex;
  align-items: center;
}

.link-cursor {
  cursor: pointer;
}

.reload-drug-profile {
  padding: 8px 10px;
  margin-right: 20px;
}

.message-inbox {
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 1px 8px rgb(0, 0, 0, 0.4);
  padding: 0 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  max-width: 600px;
}

.message-inbox-header {
  border-bottom: 1px solid darkgrey;
}

.message-inbox-body {
  overflow: auto;
  padding-top: 10px;
  margin-bottom: 1px;
  background-color: #f2f2f2;
  max-height: 300px;
  min-height: 275px;
}

.message-inbox-detail {
  margin-bottom: 35px;
}

.sender {
  color: #798AA4;
  font-weight: 600;
}

.sender-patient {
  margin-top: 0px;
}

.content {
  background-color: #009CDC;
  color: white;
  margin-left: 20px;
  padding: 10px 30px 10px 10px;
  width: auto;
  max-width: 400px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 0px;
  white-space: pre-line;
}

.send-time {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 12px;
}

.message-inbox-detail-patient {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 8px;
}

.content-patient {
  background-color: #fff;
  color: black;
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  margin-top: 0px;
  margin-right: 20px;
  width: auto;
}

.send-time-patient {
  margin-right: 15px;
  color: #4e4e53;
}

.message-inbox-footer {
  padding: 10px 0px;
}

.input-message-inbox {
  font-size: 16px;
  resize: none;
}

.input-message-inbox-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.input-message-inbox {
  resize: none;
  width: 100%;
  font-size: 14px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #d5d5d5;
}

.message-inbox-body {
  .cdk-virtual-scroll-content-wrapper {
    top: 10px;
  }
}

.btn-send-message-inbox {
  border: none;
  outline: none;
  background-color: transparent;
  color: #053057;
  cursor: pointer;
  opacity: 0.8;
}

.btn-send-message-inbox:hover {
  opacity: 1;
  color: #009cdc;
}

.message-inbox-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-close-message-inbox {
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 0.8;
}

.btn-close-message-inbox:hover {
  opacity: 1;
}

.message-information-container {
  margin-top: 5px;
  width: 100%;

  .title-message-pharmacy {
    margin: 0px;
    margin-bottom: 3px;
    font-weight: 700;
  }

  .message-content {
    width: 85%;
    background-color: #CFCFCF;
    padding: 15px 30px 15px 10px;
    border-radius: 0px 10px 10px 10px;
  }

  .count-message p {
    margin-top: 5px;
  }
}

.drugs-table {
  cursor: pointer;
  width: 100%;

  tr:nth-child(even) {
    background-color: #dddddd;
  }

  .drug-item {
    display: grid;
    grid-template-columns: 3fr 1fr;
    width: 100%;

    .day-left {
      text-align: center;
    }

    .red-warning {
      text-align: center;
      color: red;
    }
  }
}

.chat-box-area {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;

  p {
    color: rgba(0, 0, 0, 0.349019607843137);
    padding: 0px 10px;
  }

  .image-warning {
    position: relative;
    align-self: center;
  }
}

.card-status {
  border-top: 2px solid #053057;
  border-bottom: 2px solid #053057;
  margin: 10px 0 10px 0;
  padding: 10px 0 20px 0;

  .smart_card {
    font-size: 40px;
  }
}

.card-icon-container {
  text-align: center;
}

.card-status.inserted {
  .smart_card {
    color: #053057;
  }

  .highlight {
    font-weight: bold;
  }
}

.card-status.not-inserted {
  .smart_card {
    color: red;
  }

  .highlight {
    color: red
  }

  /* used to hide scr when card is not insterted */
  display: none;
}

.card-status-details {
  font-size: 12px;
  margin: 0px;
}

.auditrow {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  min-height: 50px;
  white-space: pre-line;

  &.date-time-field {
    width: 100%;
    text-align: center;
    flex-wrap: wrap;
    /* allow datetime parts to wrap */
  }

  .datepart {
    white-space: nowrap;
    padding-right: 0.5em;
  }

  .timepart {
    white-space: nowrap;
    padding-right: 0.3em;
  }

  .offsetpart {
    white-space: nowrap;
    font-size: 65%;
    position: relative;
    top: -0.3em;
  }
}

.audit-list-des {
  font-weight: bold;
  border-bottom: 1px solid grey;
}

.audit-gird-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .audit-col {
    font-size: 13px;

    * {
      margin: 3px 0;
    }

    .audit-item {
      display: flex;

      .audit-bold {
        font-weight: bold;
        color: #053057;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .audit-role-icon {
    margin-right: 15px;
  }
}

.audit-row {
  align-items: center;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 6fr 2fr 2fr 3fr 5fr 2fr;
  border-bottom: 1px solid #000;
  grid-template-columns: 6fr 2fr 2fr 3fr 5fr 2fr;
  position: relative;
  border-right: solid 1px #E5E5E5;
}

.audit-headers {
  background-color: #E5E5E5;
  font-weight: bold;
  padding: 0px;
  padding-right: 20px;
  align-items: center;
  max-height: 55px;
  height: 55px;
  min-height: 55px;
  border-bottom: solid 1px white !important;
  text-align: center;
  vertical-align: middle;
}

.auditrow {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  min-height: 50px;
  white-space: pre-line;
  padding: 0 10px;
  border-right: solid 1px #E5E5E5;

  .date-time-field {
    width: 100%;
    text-align: center;
  }
}

.emergency-access-col {
  overflow-y: auto;
  height: 40px;
}

.audit-list-container .inverse-btn {
  margin-left: 10px;
}

.audit-list-container .audit-main-grid-panel {
  height: calc(100%);
  background-color: #FFF;
  /*padding-bottom: 60px;*/
}

.audit-list-container .audit {
  max-height: 50px;
  height: 50px;
  border-bottom: solid 1px grey;
}

.audit-list-container .audit-list .gridrow {
  align-items: center;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.audit-list-container .auditrow {
  word-break: break-all;
}


.audit-list-container .gridrow.headers.withfilters {
  /* This calc for width is to align columns inside the scroll area with columns outside it. */
  width: 100%;
  background-color: #E5E5E5;
}

.audit-list-container .gridrow.headers div {
  padding: 2px;
  height: 61px;

}

.audit-list-container .filter-item input {
  width: 100%;
}

.audit-list-container .audit-list.gridrow.headers.withfilters select {
  font-size: 12px;
  padding: 2px;
  line-height: 16px;
  width: 100%;
}

.audit-list-container .pending-audit.withfilters {
  height: 35px;
  width: 100%;
  background-color: #E5E5E5;
}

.audit-list-container .gridrow.pending-audit.withfilters select {
  font-size: 12px;
  padding: 2px;
  height: 20px;
  line-height: 16px;
  width: 100%;
}

.audit-list-container .gridrow.pending-audit.withfilters div {
  border-left: solid 1px white;
  font-size: 1em;
  font-weight: bold;
}

.audit-list-container .audit-bold {
  font-weight: bold;
}

.audit-list-container .audit-subtext {
  font-style: italic;
}

.audit-list-container .audit-list {
  margin: 10px;
  margin-top: 0px;
  height: calc(100% - 15px);
}

.audit-list-container .main-heading {
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  text-align: left;
  padding: 10px;
}

.audit-list-container .page-description {
  padding: 10px;
}

.audit-list-container .line-primary {
  height: 5px;
  background: #053057;
}

.audit-list-container .audit-active {
  display: grid;
  height: 100%;
}

.audit-list-container .audit-pending {
  display: grid;
  height: 100%;
}

.audit-list-container .audit-header {
  min-height: 92px;
  height: 92px;
}

.audit-list-container {
  .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: white;
    border-radius: 5px;
    padding: 0px;
    margin: 0px;
    vertical-align: middle;
    border: 0px;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0px;
    margin: 5px;
    vertical-align: middle;
    border: 0px;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
  }

  .mat-form-field-wrapper {
    padding: 0;
    margin: 0;
  }
}

.bookingDialogMessage {
  font-size: 12px;
  margin-left: 125px;
  margin-top: -15px;
}

.bookingMessage12px {
  font-size: 12px;
}
