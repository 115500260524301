$mobile-primary-color: #0F577B;
$mobile-background-color: #E6EEF1;
$mobile-text-color: #222222;
$mobile-spinner-color :#6F9AAF;
$mobile-secondary-color: #F2F6F8;
$bright-highlight: #009cdc;
$separator-line: #d5d5d5; // lines used to separate sections of the page
$header-colour: #00217f;
$background-colour: #efefef;
$primary-button-colour: #00217f;
$default-button-colour:#04529c;
$cancel-button-colour: grey;
$raised-cancel-button-colour: #fff;
$button-text-colour: #fff;
$cancel-button-text-colour: #00217f;
$button-disabled-text-colour: grey;
$cancel-button-disabled-text-colour: #a0a0a0;
$separator-lines-spec: 1px solid #{$separator-line};
$error-colour: red;
$error-text-colour: #{$error-colour};
$drug-space-line-colour: #9ba7daff;
$drug-space-lines-spec: 2px solid #{$drug-space-line-colour};
$list-edit-colour: #ffc81233;
$list-edit-border: 4px solid #{$list-edit-colour};
$minor-button-background-colour: #f2f2f2ff;
$minor-button-text-colour: #333333ff;
$minor-button-text-hover-colour: #04539cff; //4 82 156
$btn-primary: #00217F;
$btn-default-blue: #04529C;
$btn-secondary-dusk: #FF5153;
$btn-secondary-sky: #5586FF;
$btn-disable: #E0E0E0;
$btn-soft: #FFFFFF;
$success-colour: #18e144;