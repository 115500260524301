@import "style-old.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "colors.scss";

// General css that is global in scope
:root {
  // define default values for all objects
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 400;

  .minor-title {
    display: inline-block;
    color: #333333;
    font-size: 16px;
  }

  .success-icon {
    color: $success-colour;
  }

  .spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // General defaults for inputs and buttons
  .local-button {
    font-size: 13px;
    background-color: #{$minor-button-background-colour};
    color:#{$minor-button-text-colour} !important;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 35%);
    height: 35px;

    &:hover {
      color:#{$minor-button-text-hover-colour} !important;
    }

    &:disabled,
    &:hover:disabled {
      color:#{$button-disabled-text-colour} !important;
    }
  }

  .mat-raised-button {
    margin-right: 10px;
  }

  mat-icon.prefix {
    margin-right: 10px;
  }

  mat-icon.suffix {
    margin-left: 10px;
  }

  mat-icon.searchicon {
    outline: none;
    font-size: 20px;
    height: auto;
    width: auto;
    position: relative;
    left: -23px;
    background-color: #ffffff80;
    cursor: pointer;
  }

  .button-set {
    display: flex;
    margin-top: 5px;

    button {
      margin-right: 10px;
    }

    :last-child {
      margin-right: 0px;
    }

    .button-subset {
      margin-right: 10px;
      display: flex;
    }
  }

  .buttons-after {
    display: flex;
    width: 100%;

    >div {
      flex: 0 0 auto;
    }

    >div:first-of-type {
      flex: 1;
    }
  }

  .rowaction-set {
    margin-left: 20px;

    .rowaction-text {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .validation-error {
    color: #{$error-text-colour};
    font-size: 12px;
  }

  .data-row-error {
    color: #{$error-text-colour};
    font-weight: bold;
  }

  // NHS specific display
  .ods-code {
    text-transform: uppercase;
  }

  .dialog-content {
    width: 840px;
    margin: 20px 30px;
  }

  .dialog-header-container {
    height: 95%;
    width: 100%;

    .close-button {
      cursor: pointer;
      display: flex;
    }

    .header {
      border-radius: 4px;
    }
  }

  .warning-button {
    background-color: #{$error-colour};
  }

  .row-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 33px;
    position: relative;

    &__left {
      font-family: "Poppins", "Roboto", sans-serif;
      font-size: 32px;
      line-height: 45px;
      font-weight: bold;
      color: $btn-primary;
    }

    &__center {
      margin-top: 0px;
      margin-bottom: 4px;
    }

    &__right {
      display: flex;
      margin-top: 0px;
      margin-bottom: 4px;

      &> :not(:last-child) {
        margin-right: .25rem;
      }
    }
  }

  .d-flex {
    display: flex;
  }


}

// General Page Layout
body {
  background-color:#{$background-colour};
  font-size: 14px;
  margin-bottom: 180px;
  overflow: hidden;
  padding-bottom: 20px;
}

a {
  color: #{$bright-highlight};
  text-decoration: underline;
  font-weight: 700;

  &.disabled {
    color:#{$button-disabled-text-colour};
    pointer-events: none;
  }
}


label {
  font-weight: bold;
  margin-right: 5px;
}

input {
  &.tiny-input {
    width: 32px;
    height: auto;
    margin-right: 5px;
  }
}

nav.page-nav label {
  margin-right: 0px;
}

.last-name {
  text-transform: uppercase
}

.first-name {
  text-transform: lowercase;
  display: inline-block;
  text-decoration: inherit;
}

.first-name::first-letter {
  text-transform: uppercase;
}

.hidden-input {
  display: none;
}

// Layout for specific pages
app-patient-addpage,
app-patientdetails,
app-emergency-access,
app-patientexternalsearch {

  // when pratical, move these up into general rules.
  button {
    height: 32px;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    background-color: #{$primary-button-colour};
    color: #{$button-text-colour};
    border: 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    padding: 6px 10px;

    &.mat-raised-button {
      background-color: #{$primary-button-colour};
      color: #{$button-text-colour};

      &.cancel {
        background-color: #{$raised-cancel-button-colour};
        color: #{$cancel-button-text-colour};
      }
    }

    &.primary {
      &:disabled {
        color: #{$button-disabled-text-colour};
        cursor: unset;
      }
    }

    &.cancel {
      background-color: #{$cancel-button-colour};
      color: #{$cancel-button-text-colour};

      &:disabled {
        color: #{$cancel-button-disabled-text-colour};
        cursor: unset;
      }

    }
  }



  H2 {
    color: #{$bright-highlight};
    font-size: 26px;
    margin-top: 12px;
  }

  H3 {
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    border-bottom: #{$separator-lines-spec};
    padding-bottom: 20px;
    margin-bottom: 0px;

    &.no-underline {
      border-bottom: none;
    }
  }

  .header-underline {
    // to put header underlining on other objects
    border-bottom: #{$separator-lines-spec};
  }

  section.medication {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 10px;

    h2 {
      width: 95%;
      flex: 0 0 auto;
    }

    section.scr-display,
    section.drug-display {
      min-width: 450px;
      padding: 0 40px;
      /* combines with drug and scr display margins for 40px outer margins */
      flex: 1 1 0;
      /*max-width is used when displaying the drugs with no SCR */
      max-width: 750px;
    }

    section.scr-display {
      order: 1;
      padding-right: 20px; //use for all but last item in list.

      .loadingScr {
        width: 80%;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .patientScrLoaded {
        .html_summary {
          width: 100%;
          height: 400px;
          max-height: 400px;
          overflow: auto;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .patient-permission {
          width: 100%;
          border-top: #{$separator-lines-spec};
        }
      }
    }
  }

  section.drug-display {
    order: 2;

    .medication-header button {
      margin-top: 10px;
    }

    app-patientdrugrecord {
      .search-all-content {
        .search-text {
          width: 300px;
          outline: none;
        }

        .error-message {
          position: absolute;
          font-size: 11px;
          margin-top: 15px;
          margin-left: 36px;
        }
      }
    }

    .drugs-list {
      max-height: 520px;
      overflow: auto;
    }

    .drugs-list-all {
      max-height: fit-content;
    }

    .drug-dialog-content {
      position: absolute;
      z-index: 10;
      padding: 1px;
      width: 420px;
      background-color: #{$background-colour};
      border: #{$separator-lines-spec};

      .drug-content {
        width: calc(100% - 2px);
        margin-left: 1px;

        .drug-list-viewport {
          max-height: 120px;
          height: 120px;
          //                display: contents;
          overflow-x: hidden;

          .cdk-virtual-scroll-content-wrapper {
            //position: relative;
          }

          .drug-option-list {
            width: 100%;
            padding: 5px;
            display: flex;
            cursor: pointer;

            &:hover {
              background-color: #00217f;
              color: #fff;
            }

            .left {
              width: 40%;
              padding-right: 20px;
            }

            .middle {
              width: 30%;
            }

            .right {
              width: 30%;
            }
          }
        }
      }
    }

    #button-add-medication {
      min-width: 200px;
    }

    .drug-item {
      border-bottom: #{$drug-space-lines-spec};
      padding: 7px 0;

      //                    margin-bottom: 10px;
      .viewdrug,
      .editdrug {
        padding: 10px;
        position: relative;

        .drug-name {
        }

        .drug-name:first-of-type {
          width: 40%;
        }

        .drug-order {
          display: flex;
          align-items: center;
        }

        .drug-edit-line {
          width: 100%;
          margin-top: 10px;
          max-width: 700px;
          justify-content: space-between;
          display: flex;
          flex-wrap: wrap;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 14px;
          text-align: left;
          grid-template-columns: 1.8fr 0.6fr 1.05fr;

          &.withSupply {
            grid-template-columns: 1.8fr 0.6fr 1.05fr 1.2fr;
          }

          .drug-edit-col-1-row-1 {
            min-width: 180px;
          }

          .drug-edit-col-1-row-2 {
            min-width: 180px;
          }

          .drug-edit-col-2-row-1 {
            min-width: 60px;
          }

          .drug-edit-col-3-row-1 {
            min-width: 105px;
          }

          .drug-edit-col-4-row-1 {
            min-width: 120px;
            white-space: nowrap;
          }

          .drug-edit-col-wide {
            min-width: 200px;
          }

          .drug-edit-text {
            padding-top: 6px;
          }

          .erd-icon {
            vertical-align: middle;
            line-height: 21px;
            height: 21px;
          }
        }

        .drug-final-line {
          margin-top: 10px;
        }

      }

      //     transition: 1s ease-in-out;I
      .editdrug {
        //         transform: translate(3em,0);
        border: #{$list-edit-border};
        border-radius: 10px;

        input.search-text {
          width: 80%;
          padding-right: 22px;
        }
      }

      .viewdrug {
        padding: 0px 14px 5px; // matches edits padding 10 + border 4
        display: flex;
        flex-wrap: wrap;

        .drug-edit-col-2 {
          label {
            position: relative;
            top: -2px;
          }

          mat-icon {
            position: relative;
            top: 2px;
            height: 20px;
            font-size: 20px;
          }
        }
      }

    }

    .drug-button-set-container {
      display: block;
      height: 40px;
      max-width: 700px;
    }

    .drug-button-set {
      float: right;
    }
  }
}

app-set-password-page .set-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white !important;
  height: 80vh;
  padding: 15px;

  .w-100 {
    width: 100% !important;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #4e4e53 !important;
  }

  &__description {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #4e4e53 !important;
    margin: 20px 0 30px;

    &.-left {
      text-align: left;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 30%;
  }


  .mat-form-field {
    width: 100%;
    margin-bottom: 26px;
  }


  .mat-form-field-infix {
    padding: 0;
    border-top: 0;
  }

  .mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    padding: 0 !important;
  }

  .mat-form-field-label-wrapper {
    top: -0.4em;
    padding-top: 0.94375em;
  }

  .mat-form-field-flex {
    align-items: center !important;
  }

  .mat-input-element {
    height: 42px;
  }

  .mat-error {
    font-size: 12px;
    line-height: 1.2em;
  }

  .mat-button-base {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .btn-save {
    letter-spacing: 2px;
    height: 48px;
    text-transform: uppercase;
    font-size: 16px;
    width: 110%;
    font-weight: 500;
    color: white;
    background-color: #00217f;
    border-radius: 4em;
    margin-top: 5px;
    align-self: center;
  }

  @media (min-width: 1400px) {
    &__form {
      width: 25%;
    }
  }

  @media (max-width: 1200px) {
    &__form {
      width: 30%;
    }
  }

  @media (min-width: 576px) and (max-width: 991.98px) {
    &__form {
      width: 55%;
    }
  }

  @media (max-width: 575.98px) {
    &__form {
      width: 90%;
    }

    .btn-save {
      width: 100%;
    }
  }

  input[type=text] {
    border: none !important;
    height: 48px !important;
  }

  // remove white background in case password autofill
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }
}

app-pop-up-set-password-success {
  .success-pop-up {
    padding: 20px;
    font-size: 16px;
  }

  .success-pop-up__icon {
    text-align: center;
  }

  .success-pop-up__header h1 {
    font-size: 18px !important;
    color: #4e4e53 !important;
    text-align: center;
    margin-top: 20px;
  }

  .mat-icon {
    color: #069B4F !important;
    transform: scale(2);
  }
}

page[size="A4"] {
  background: white;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

.popover.bs-popover-top-left {
  position: absolute;
  //top: 0;
  //left: 200px;
  z-index: 100;
  display: block;
  max-width: 276px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ededed;
  padding: 6px 10px;

  .time {
    text-transform: lowercase;
  }

  margin-bottom: 2px;
  box-shadow: 0 4px 8px #00000029;
  border-radius: 4px;
}

@mixin button-template {

  .btn-primary {
    background: #00217F 0 0 no-repeat padding-box;
    box-shadow: 0 0 8px #00000014;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #FFFFFF;
  }

  .btn-primary:hover {
    box-shadow: 0px 0px 8px #00000014;
    background-color: #001e73;
    border-radius: 4px;
    color: #FFFFFF;
  }

  .btn-default-blue {
    background: $btn-default-blue 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000014;
    border: none !important;
    border-radius: 4px;
    text-align: center;
    letter-spacing: 0.16px;
    color: #FFFFFF;
  

    &:hover {
      background-color: #044a92;
      box-shadow: 0px 0px 8px #00000014;
      border-radius: 4px;
    }
    
    &:disabled {
      background: #E0E0E0 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 8px #00000014;
      border: 1px solid #E0E0E0;
      border-radius: 4px;
      opacity: 1;
      pointer-events: none;
      cursor: default !important;
      user-select: none;
      color: #999999;
    }
  } 

  .btn-secondary-dusk {
    background: #FF5153 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000014;
    border-radius: 4px;
    opacity: 1;
    color: #222222;
  }

  .btn-secondary-dusk:hover {
    background-color: #e04a52;
    box-shadow: 0px 0px 8px #00000014;
    border-radius: 4px;
    opacity: 0.12;
  }

  .btn-secondary-sky {
    background: #5586FF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000014;
    border-radius: 4px;
    opacity: 1;
    color: #222222;
  }

  .btn-secondary-sky:hover {
    background-color: #4b78e9;
    box-shadow: 0px 0px 8px #00000014;
    border-radius: 4px;
  }
  .button-dialog {
    border: none;
    outline: none;
    font-size: 16px;
    border-radius: 4px;
    box-shadow: 0 0 8px #00000014;
    height: 43px;
    min-width: 76px;
    cursor: pointer;
    margin-left: 12px;
  }

  .btn-submit {
    background-color: #04529C;
    color: #FFFFFF;
    padding: 12px 20px;
  }

  .btn-cancel-dialog {
    border: 1px solid #00217F;
    color: #04529C;
    background-color: #FFFFFF;
    min-width: 76px;
    padding: 12px 20px;
  }

  .btn-cancel-dialog:hover {
    background-color: #f0f1f4;
  }

  .btn-submit:hover {
    background-color: #044a92;
  }

  .btn-ghost {
    box-shadow: 0px 0px 8px #00000014;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #00217F;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #00217F;
  
      &:disabled {
        background: #E0E0E0 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 8px #00000014;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        opacity: 1;
        pointer-events: none;
        cursor: default !important;
        user-select: none;
        color: #999999;
      }

      &:hover {
        background-color: #f0f1f4;
        box-shadow: 0px 0px 8px #00000014;
        border-radius: 4px;
        color: #00217F;
      }
  } 

  .btn-soft {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000014;
    border: 1px solid #E0E0E0;
    border-radius: 22px;
    color: #222222;
  }

  .btn-soft:hover {
    background-color: #f0f1f4;
    box-shadow: 0px 0px 8px #00000014;
    border-radius: 22px;
  }

  .btn-disable {
    background: #E0E0E0 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000014;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    opacity: 1;
    pointer-events: none;
    cursor: default !important;
    user-select: none;
    color: #999999;

    .material-icons {
      color: #999999;
    }
  }

  .btn-icon {
    width: 48px;
    height: 43px;
    cursor: pointer;
    outline: none;
    padding: 10px 12px 9px 12px;
  }

  .btn-text {
    font-size: 16px;
    font-weight: 500;
    height: 43px;
    text-align: center;
    letter-spacing: 0.16px;
    cursor: pointer;
    padding: 12px 20px;
    outline: none;
    white-space: pre;
  }

  .btn-icon-text {
    height: 43px;
    padding: 9px 20px 10px 12px;
    cursor: pointer;
    white-space: pre;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.16px;

    span:first-child {
      margin-right: 8px;
      height: 24px;
      width: 24px;
    }

    span:last-child {
      display: inline-block;
      vertical-align: super;
    }
  }
}

.btn-right {
  margin: 16px 12px 16px auto
}

/* these were moved from style-old.scss so they can use the button template */

div.main {
  @include button-template();
  flex: 1;
  display: grid;
}

.dialog-main-content {
  @include button-template();
  padding: 10px 30px;
}


.pageContent {
  height: 98%;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  border-radius: 9px;
}


.button-group {
  @include button-template();
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 35px 20px 20px;
  height: 20vh;
}

.button-group * {
  margin: 0 5px;
}

.buttonbar {
  @include button-template();
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
}

/* End Section*/

app-view-calendar {
  @include button-template();

  h1,
  h2,
  h3 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
  }

  h1 {
    font-weight: bold;
    font-size: 32px;
    font-family: 'Poppins', 'Roboto', 'sans-serif';
    line-height: 45px;
    letter-spacing: 0;
  }

  h2 {
    font-weight: bold;
    font-size: 26px;
    font-family: 'Roboto', 'sans-serif';
    line-height: 36px;
    letter-spacing: 0;
  }

  .row {
    display: flex;
  }

  div[popoverclass*="fc-event_popover"] {
    padding: 0 1px;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  #spinner {
    -webkit-animation: frames 1s infinite linear;
    animation: frames 1s infinite linear;
    background: transparent;
    border: 5px solid #FFF;
    border-radius: 100%;
    border-top-color: $btn-default-blue;
    width: 32px;
    height: 32px;
    opacity: .6;
    padding: 0;
    position: absolute;
    z-index: 999;
  }

  @keyframes frames {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  #spinner-container {
    background-color: #efefef;
    opacity: 0.4;
    width: 100%;
    height: 150%;
    position: absolute;
    top: 4em;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .toast-header-container {
    position: fixed;
    top: 33px;
    left: 0;
    width: 100%;

    .toast-container {
      position: fixed !important;
      top: 2.4em;
      right: 3em;
    }

    div[toast-component] {
      min-width: 540px;
      height: 51px;
      font-size: 16px;
      padding: 16px;
      border-radius: 4px;
      box-shadow: 0px 4px 20px #00000029;
      color: #FFFFFF;
    }

    .toast-success {
      background-image: unset !important;
    }

    .toast-error {
      background-image: unset !important;
    }

    .custom-toast-success {
      background-color: #069B4F;
    }

    .custom-toast-error {
      background-color: #ED323B;
    }

    .custom-toast-warning {
      background-color: #FFA500;
    }
  }

  .print-completed-screen-form {
    float: right;
    font-size: 16px;
    letter-spacing: 0;
    color: #5586FF;
    font-weight: 400;
    cursor: pointer;
    display: flex;

    span {
      margin-left: 6px;
    }
  }

  .mat-form-field-infix {
    padding: .5em 0 !important;
  }

  .mat-select-value-text,
  .mat-input-element {
    color: #222222 !important;
    font-size: 16px;
  }

  .calendar-widget {
    padding: 0 150px !important;
  }

  .row {
    display: flex;
  }

  .flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .view-calendar {
    padding-right: 15px;

    .calendar-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      padding: 1px 0 16px 0;

      &__left {
        color: $btn-primary;
      }

      &__center {}

      &__right {
        display: flex;

        &> :not(:last-child) {
          margin-right: 12px;
        }
      }

      @media only screen and (max-width: 1080px) {
        &__right {
          flex-direction: column;
          height: 150px;
          justify-content: space-between;
        }
      }
    }

    .calendar-main {
      background-color: #FFFFFF !important;

      @media only screen and (max-width: 1080px) {
        &-toolbar {
          flex-wrap: nowrap;
          flex-direction: column;
          align-items: end;

          &__right {
            margin-top: 15px;
          }
        }
      }

      &-toolbar {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 16px 16px 16px;
        border-top: 1px solid #E0E0E0;
        border-left: 1px solid #E0E0E0;
        border-right: 1px solid #E0E0E0;

        &__left {
          display: flex;

          button:nth-of-type(1) {
            margin: 0 12px;
          }

          button:last-child {
            margin-left: 4px;
            margin-right: 12px;
          }

          .select-resource {
            width: 212px;

            .ng-dropdown-panel-items {}

            .hidden {
              display: none;
            }
          }
        }

        &__title {
          text-align: left;
          letter-spacing: 0;
          white-space: pre;
          color: #222222;
          align-self: center;
          font-weight: 400 !important;
        }

        &__title-small-screen-wrapper {
          margin-left: auto;
          margin-right: auto;
          margin-top: 15px;
        }

        &__title-small-screen {
          white-space: pre;
          color: #222222;
          align-self: center;
          font-weight: 400 !important;
          display: none;
        }

        @media only screen and (max-width: 1300px) {
          &__title {
            display: none;
          }

          &__title-small-screen {
            display: block;
            padding: 0 20px;
          }
        }

        &__right {
          .btn-soft.btn-icon-text.btn-disable {
            cursor: unset;
          }

          button {
            margin-right: 12px;
          }

          position: relative;
          display: flex;
          background: #FFFFFF;
          flex-grow: 1;
          flex-direction: row-reverse;

          .fc-button-group button {
            margin-right: 12px;
          }

          mat-form-field {
            width: 212px;
          }

          .select-calendar-view {
            width: 212px;
          }
        }
      }

      @media screen and (max-width: 1250px) {
        .fc-custom-toolbar {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          mat-form-field {
            width: 212px;
          }
        }
      }

      &-body {
        position: relative;
        border-bottom: 1px solid #E0E0E0;
        box-shadow: 0 4px 8px #00000014;

        full-calendar {
          .fc-custom-popover {
            top: 40em !important;
          }

          .fc-timegrid-slots tr td {
            height: 30px !important;
          }

          .fc-theme-standard td,
          .fc-theme-standard th {
            border: 1px solid #E0E0E0 !important;
          }

          .fc-day-other .fc-daygrid-day-top {
            opacity: 1 !important;
          }

          .fc-day-other .fc-daygrid-day-number {
            color: #CCCCCC !important;
            font-weight: 400 !important;
            text-decoration: none;
          }

          a {
            color: #222222 !important;
            font-weight: 500;
            text-decoration: none;
          }

          .fc-more-link {
            color: #222222;
            font-weight: bold;
          }

          ::-webkit-scrollbar {
            width: 2px;
          }

          ::-webkit-scrollbar-thumb {
            background-color: #e0e0e0;
          }

          .fc-popover-header {
            .fc-popover-title {
              position: relative;
              text-align: left;
              padding-left: 5px;
              font-size: 24px !important;
              line-height: 31px;
              letter-spacing: 0;
              color: #222222;
            }

            background: #FFFFFF !important;
          }

          .icon-x {
            cursor: pointer;
            color: #222222;
            font-size: 24px !important;
            padding-right: 9px;
          }

          .fc-daygrid-event-harness {
            margin-bottom: 3px;
            height: 22px;
          }

          .fc-daygrid-event-harness:hover {
            a {
              border-width: 2px;
              background: #E5E8F2;
            }
          }

          .fc-event-time {
            font-weight: 400 !important;
          }

          .fc-event-time:after {
            content: '' !important;
          }

          .fc-daygrid-more-link {
            color: #222222 !important;
          }

          .fc-timegrid-event-harness {
            overflow: hidden;
          }

          .fc-day-past.selected-day {
            background: rgba(255, 220, 40, .15) !important;
          }

          .fc-non-business {
            background-image: linear-gradient(120deg, #e0e0e0 3.85%, #f6f7fc 3.85%, #f6f7fc 50%, #e0e0e0 50%, #e0e0e0 53.85%, #f6f7fc 53.85%, #f6f7fc 100%) !important;
            background-size: 15px 26.00px !important;
          }

          .fc-day-today {
            .fc-non-business {
              background-image: linear-gradient(120deg, #e0e0e0 3.85%, #fff3de 3.85%, #fff3de 50%, #e0e0e0 50%, #e0e0e0 53.85%, #fff3de 53.85%, #fff3de 100%) !important;
              background-size: 15px 26.00px !important;
            }
          }

          .fc-col-header-cell-cushion {
            color: #222222;
            font-weight: 500;
          }

          .fc-dayGridMonth-view {
            .fc-scrollgrid-sync-table td {
              height: 150px;
            }
          }

          .fc-toolbar-ltr {
            margin-bottom: unset !important;
          }

          @media screen and (max-width: 1250px) {
            .fc-header-toolbar {
              margin: 10px auto 15px;
            }

            .fc-toolbar.custom {
              display: flex;
              justify-content: space-between;
            }

            .fc-toolbar h2 {
              margin: 0 !important;
              font-size: 26px !important;
            }
          }

          //#region Col header cell
          // Align text and font size
          .fc-dayGridWeek-view,
          .fc-timeGridWeek-view {
            .fc-col-header-cell {
              font-weight: 400 !important;
            }
          }

          .fc-col-header-cell {
            vertical-align: middle;
            font-size: 16px;
          }

          .fc-col-header-cell.fc-day-today {
            background: transparent linear-gradient(180deg, #FFF3DE00 0%, #FFF3DE 100%) 0 0 no-repeat padding-box;
          }

          // Boder bottom shadow
          .fc-scroller-harness {
            box-shadow: 0 4px 8px #00000014;
            margin-bottom: 1.5px;
          }

          // dayHeaderClassNames
          .week-header::after {
            content: attr(data-date);
            font-size: 22px;
            color: #222222;
            font-weight: 400;
          }

          .fc-col-header {
            height: 77px !important;
          }

          .fc-col-header th {
            border-left: none !important;
            border-right: none !important;
            border-bottom: 1px solid #E0E0E0 !important;
          }

          .fc-col-header-cell-cushion {
            vertical-align: inherit;
            padding: 0;
            text-decoration: none;
          }

          .fc-col-header-cell {
            border-bottom: 2px solid #ddd !important;
          }

          //#endregion

          //#region Date cell
          .fc-daygrid-day-number {
            font-size: 22px;
            padding: 4px 12px !important;
          }

          .fc-day-today .fc-daygrid-day-number {
            color: #FF5153 !important;
          }

          //#endregion

          //#region Event
          // Scroll for popover body
          .fc-popover-body {
            height: 240px;
            overflow: auto;
          }

          .fc-popover {
            min-width: 20%;
            max-width: 30%;
            z-index: 10 !important;
          }

          .fc-event-main-frame {
            font-size: 15px;
            display: flex;
            flex-direction: row;
          }

          .fc-event-main {}

          .fc-more-link {
            font-size: 15px;
          }

          .fc-event-time,
          .fc-event-title {
            color: #222222 !important;
            padding: 2px 0;
            font-size: 15px;
            margin-left: 2px;
          }

          .fc-event-title-container {
            .fc-event-title {
              font-weight: 700;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .fc-daygrid-event-dot {
            margin: 6px 4px;
            box-sizing: content-box;
            border-radius: 10px;
          }

          //#endregion

          //#region View
          // Month
          .fc-dayGridMonth-view {
            .fc-scrollgrid-sync-table tr:last-child {
              height: 10em;
            }

            .fc-event-time {
              max-width: 100px;
              min-width: 60px;
            }
          }

          // Week
          .fc-dayGridWeek-view {
            .fc-event-time {
              max-width: 100px;
              min-width: 60px;
            }
          }

          // Day
          .fc-resourceDayGridDay-view {
            .fc-event-time {
              max-width: 100px;
              min-width: 60px;
            }

            .fc-daygrid-day.fc-day-today {
              background: #FFFFFF !important;
            }
          }

          // Week Schedule
          .fc-timeGridWeek-view {
            .fc-event-title {}
          }

          // Day schedule
          .fc-resourceTimeGridDay-view {
            .fc-event-title {}

            .fc-day-today {
              .fc-non-business {
                background-image: linear-gradient(120deg, #e0e0e0 3.85%, #ffffff 3.85%, #ffffff 50%, #e0e0e0 50%, #e0e0e0 53.85%, #ffffff 53.85%, #ffffff 100%) !important;
                background-size: 15px 26.00px !important;
              }
            }

            .fc-timegrid-col.fc-day-today {
              background: #FFFFFF !important;
            }
          }

          .fc-dayGridWeek-view,
          .fc-resourceTimeGridDay-view,
          .fc-resourceDayGridDay-view {
            .fc-scrollgrid-sync-table {
              min-height: 750px;
            }

            .custom-background {
              background-color: #FFFFFF;
            }
          }

          // TODO: check which override this style
          .fc-listWeek-view,
          .fc-listDay-view {

            //div[popoverclass*="fc-event_popover"]
            table.fc-list-table tr:hover td {
              background: #e0e0e0;
            }

            .fc-list-event {
              cursor: pointer;
              border-bottom: 1px solid #ddd !important;

              td {
                border: none !important;
              }
            }

            .fc-list-table tr,
            tr>div {
              height: 36px;
            }

            .fc-list-table th {
              height: 100%;
              background: #E5E8F2 0 0 no-repeat padding-box;
              border: 1px solid #E0E0E0;
            }

            .fc-list-table {

              .fc-list-day-text,
              .fc-list-day-side-text {
                font-size: 16px;
                font-weight: bold;
                padding-left: 4px;
              }

              .fc-list-event-time {
                color: #222222;
                font-size: 16px;
                padding: 9px 20px 8px !important;
              }

              .fc-list-event-graphic {
                .fc-list-event-dot {
                  border: 7px solid #5072C6;
                  border-radius: 14px !important;
                }

                padding: 12px 0 11px !important;
              }

              .fc-list-day-cushion {
                background: #E5E8F2 0% 0% no-repeat padding-box !important;
              }

              .fc-list-event-title {
                font-size: 16px;
                color: #222222;
                padding: 9px 12px 8px !important;

                a {
                  font-weight: bold !important;
                }
              }
            }
          }

          // Week timeline
          .fc-resourceTimelineWeek-view {
            .fc-event-title {
              text-overflow: clip !important;
            }
          }

          // Day timeline
          .fc-resourceTimelineDay-view {
            .fc-event-title {
              text-overflow: clip !important;
            }
          }

          //#endregion
          .fc-resource-timeline {
            .fc-scroller {
              overflow: auto !important;
            }

            .fc-datagrid-cell-main {
              color: #222222;
              font-size: 16px;
            }

            .fc-timeline-header-row {
              a {
                font-weight: 500 !important;
                font-size: 16px;
                color: #222222;
              }
            }

            .fc-timeline-slot-frame {
              height: 33.5px;
            }

            .fc-datagrid-cell-cushion {
              padding: 9px 0 8px 20px !important;
            }

            .fc-timeline-event {
              border-radius: 4px;
            }

            .fc-datagrid-cell.resource-header {
              font-weight: 500;
              color: #222222;
            }
          }
        }

        /* Next & previous buttons */
        .prev-resources,
        .next-resources {
          cursor: pointer;
          position: absolute;
          top: 40%;
          width: auto;
          padding: 100px 20px;
          margin-top: -22px;
          color: #ddd;
          font-weight: bold;
          font-size: 40px;
          transition: 0.6s ease;
          border-radius: 0 3px 3px 0;
          user-select: none;
          z-index: 100;
          opacity: 0.6;
        }

        /* Position the "next button" to the right */
        .next-resources {
          right: 0;
          border-radius: 3px 0 0 3px;
        }

        /* On hover, add a black background color with a little bit see-through */
        .prev-resources:hover,
        .next-resources:hover {
          background-color: rgba(0, 0, 0, 0.8);
        }
      }

      &-monthly-metrics {
        padding: 15px 15px 100px;

        &__title {
          color: #222222;
          margin-bottom: 16px;
        }

        &__body {
          .row {
            border-bottom: 1px solid #dddddd;
            border-left: 1px solid #dddddd;
            border-right: 1px solid #dddddd;
            padding: 2px 10px;
            justify-content: space-between;
          }

          .row:first-child {
            border-top: 1px solid #dddddd;
          }

          &-item {
            max-width: 628px;

            span {
              font-weight: bold;
              font-size: 15px;
              color: #222222;
            }
          }

          &-service-name {
            width: 90%;
            color: #222222;
            font-size: 15px;
            font-weight: 400;

            strong {
              font-size: 16px;
            }
          }

          .service-time {
            margin: 0 17px;
            align-self: center;
          }
        }
      }
    }
  }

  td[data-day=fill] {
    background: rgba(40, 255, 244, 0.15) !important;
  }
}

app-loading-spinner {
  .overlay-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.app-booking-dialog-date-picker {
  .mat-calendar-body-cell-content.mat-calendar-body-today.mat-calendar-body-selected {
    background: #04529C !important;
    box-shadow: none !important;
    color: white;
  }

  .mat-calendar-body-cell-content.mat-calendar-body-selected {
    background: #04529C !important;
    color: white;
  }

  .mat-calendar-body-cell-content:hover {
    background: #E5E8F2 !important;
  }

  .mat-calendar-previous-button,
  .mat-calendar-next-button,
  .mat-calendar-period-button,
  .mat-calendar-body-cell-content {
    color: #222222;
  }

  .mat-mdc-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
      padding: 13px 0 !important;
    }

    .mat-mdc-form-field-flex {
      padding: 0 12px !important;
    }
  }
}

app-booking-dialog {
  position: relative;
  display: block;
  @include button-template();

  .row-header {
    padding: 33px;
    color: $btn-primary;
  }

  .row-footer {
    padding: 0 50px 0 33px;
    margin: 1em 0 !important;
  }

  .row-body {
    padding: 0px 33px !important;
    max-height: calc(80vh - 160px);
  }


  hr {
    border: 0.2px solid #E0E0E0;
    margin-top: 20px;
    margin-bottom: 24px;
  }

  input[type="radio"],
  input[type="checkbox"] {
    margin: 0 4px 8px 0;
  }

  .section-dialog-title {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    color: #222222;
    opacity: 1;
    display: block;
  }

  .input-dialog-label {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0;
    color: #222222;
    opacity: 1;
    margin-bottom: 8px;
  }

  .flex-space-between {
    justify-content: space-between;
  }

  .flex-column {
    flex-direction: column;
  }

  //#region custom material
  .mat-mdc-form-field-infix {
    border-top: 0.3em solid transparent !important;
  }

  .mat-error {
    font-size: 12px;
  }

  .mat-mdc-form-field-appearance-outline {
    color: $btn-primary  !important;
  }

  .select-panel.mat-mdc-select-panel {
    min-width: 100% !important;
  }

  .mat-mdc-select-value-text,
  .mat-mdc-input-element {
    color: #222222 !important;
    font-size: 16px;
  }
  
  .mat-mdc-checkbox.mat-accent .mat-checkbox-background, .mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background {
    background-color: $btn-default-blue !important;
    border-color: $btn-default-blue  !important;
  }

  .mat-checkbox-inner-container {
    height: 18px;
  }

  .mat-mdc-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px !important;
  }

  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
    margin: 0 !important;
  }

  .mat-form-field-disabled {
    background: #EFEFEF 0% 0% no-repeat padding-box !important;
    border-color: #E0E0E0 !important;
    border-radius: 4px;
  }

  .mat-calendar-body-cell-content.mat-calendar-body-selected {
    background: #04529C !important;
    color: white;
  }

  .mat-calendar-body-cell:hover {
    background: #E5E8F2 !important;
  }

  .patient-mobile,
  .patient-dob,
  .patient-email,
  .date-field {
    &.mat-form-field-appearance-outline.ng-valid.ng-touched.hasValue {
      .mat-form-field-outline {
        color: #069B4E;
        border-radius: 4px;
      }

      .mat-form-field-appearance-outline.mat-focused {
        .mat-form-field-outline {
          opacity: 0;
        }
      }

      .ng-dirty.ng-valid .mat-form-field-outline.mat-form-field-outline-thick {
        color: $btn-primary;
        border-radius: 4px;
      }
    }
  }

  .errorMessage {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.39px;
    color: #ED323B;
    padding: 5px 0;
  }

  .successMessage {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.39px;
    color: #069B4E;
    padding: 5px 0;
  }

  //#endregion

  button {
    background: transparent;
    border: none;
    outline: none;
  }

  .picker-time {
    max-width: 166px;
    margin-top: 2px !important;
  }

  timepicker {
    margin-top: 3px;
    text-align: center;

    .form-group input[type=text] {
      border-radius: 4px;
      min-height: 43px;
      font-size: 16px;
      max-width: 45px;
      text-align: center;
    }

    .form-group input[type=text]:focus {
      border-color: #04529c;
      outline: none;
    }

    &.hour-invalid {
      .form-group:first-child {
        .form-control.bs-timepicker-field {
          border-color: #ed323b;
        }
      }
    }

    &.min-invalid {
      .form-group:not(:first-child) {
        .form-control.bs-timepicker-field {
          border-color: #ed323b;
        }
      }
    }

    &.form-picker-invalid {
      .form-control.bs-timepicker-field {
        border-color: #ed323b;
      }
    }

    button.btn {
      background-color: #fff;
      border: 1px solid #E0E0E0;
      border-radius: 4px;
      box-shadow: 0 0 8px #00000014;
      font-size: 16px;
      outline: 0;
      padding: 11px;
      text-transform: lowercase;
    }

    .btn-link {
      display: inline-block;
      color: #222222;
      padding: 4px 6px;

      span {
        box-sizing: border-box;
      }
    }

    table {
      border-collapse: collapse;
    }
  }

  .item-control {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 14px;
    }

    &.patient-full-name {
      margin-bottom: 10px;

      .btn-soft.btn-icon {
        margin-left: 2px;
      }
    }

    .patient-first-name {
      width: 201px;
      margin-right: 12px;
    }

    .patient-last-name {
      width: 202px;
      margin-right: 12px;
    }

    .patient-dob,
    .patient-email {
      width: 415px;
    }

    .patient-mobile {
      width: 208px;
    }

    .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
      min-height: 46px;
      padding-top: 6px !important;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
      border-color: #e0e0e0 !important;
      height: 43px !important;
      box-shadow: 0px 0px 8px rgb(0 0 0 / 8%) !important;
    }
  }

  .item-center {
    align-items: center;
  }

  .date-time {
    width: 171px;
    margin-right: 12px;
  }

  .patient-email {
    width: 420px;
  }

  .patient-booking {
    display: flex;
    margin-top: 15px;
    width: 910px;

    h3 {
      margin-top: 0 !important;
    }

    &__left {
      width: 475px;
    }

    &__right {
      margin-left: 32px;

      textarea {
        font-size: 16px;
        color: #222222;
        height: 194px !important;
        width: 403px;
        resize: none;
        background: #FFFFFF 0 0 no-repeat padding-box;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        padding: 13px 12px;
      }

      textarea:hover {
        border: 1px solid $btn-default-blue;
      }

      textarea:focus {
        border: 1px solid $btn-default-blue;
        outline: none;
      }
    }
  }

  .booking-details {
    .resource-service {
      align-items: baseline;
    }

    .date-time-duration {
      margin-top: 16px;
    }

    .date-field {
      width: 97%;
      margin-right: 8px;
      margin-bottom: 21px;
    }

    .time-line {
      margin-top: 15px;
    }
  }

  .timeline-info {
    padding: 10px;
    color: #fff;
    border-radius: 6px 0 0 6px;
    min-height: 40px;
  }

  .time-slot {
    min-width: 80px;

    &:last-child {
      border-right: none;

      .time-slot__time {
        background: #666;
        border-radius: 0 4px 4px 0;
        color: #fff;
      }
    }

    &__time {
      display: inline-block;
      height: 50%;
      line-height: 30px;
      padding: 0 8px;
    }

    &__status {
      width: 100%;
      height: 50%;

      div {
        width: 25%;
      }
    }
  }

  .item-control-service-time {
    margin-bottom: 15px;
    width: 68%;
  }

  .item-control-confirmation {
    margin-top: 22px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    .label {
      text-align: left;
      font-size: 16px;
      letter-spacing: 0;
      color: #222222;
      opacity: 1;
      margin: 2px 12px 0 0;
      min-width: 130px;
    }

    mat-checkbox {
      margin-right: 8px;
    }

    .instruction {
      margin-left: 2px;
      margin-top: 2px;
      font-size: 16px;
      color: #222222;
    }

    i {
      margin-left: 2px;
      margin-top: 6px;
      color: #222222;
      font-size: 16px;
    }
  }

  .item-control-send-reminder {
    margin-bottom: 15px;
    align-self: baseline;

    mat-label {
      margin-right: 32px;
    }

    .send-reminder {
      margin: 3px 10px 0 5px;

      p {
        color: #222222;
        width: 80%;
        margin-top: 0 !important;
        margin-left: 10px;
      }
    }

    i {
      color: #222222;
      margin-left: 27px;
    }
  }

  .bookingDialogMessage {
    margin-left: 11px !important;
    margin-top: unset;
    margin-bottom: 11px;
  }

  .messageTop {
    margin-top: -15px;
  }

  .btn-more-option {
    width: 222px;
    border: 1px solid #e0e0e0;
    color: #222222;
    font-weight: 400 !important;
    background-color: #FFFFFF;
    padding: 12px !important;
    box-shadow: 0px 0px 8px #00000014;
    align-items: center;
    justify-content: space-around;
    display: flex;

    .mat-button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .btn-more-option:hover {
    border-color: #04529C;
  }

  .btn-more-option[aria-expanded="true"] {
    border-color: #04529C;
  }

  .service-type {
    margin-left: 12px;
    width: 392px;

    .service-type-input {
      width: 100%;

      p {
        white-space: nowrap;
      }

      .select-service-type {
        .ng-dropdown-panel-items.scroll-host {
          max-height: 133px;
        }
      }
    }

    mat-form-field {
      width: 100%;
    }

    .btn-w-100 {
      width: 100%;
      text-align: left;
      padding-left: 0.75em;
    }
  }

  .duration {
    margin-left: 12px;
    width: 117px;
    align-self: baseline;

    .select-duration {
      width: 117px;

      .ng-dropdown-panel-items.scroll-host {
        max-height: 100px;
      }
    }
  }

  .resource {
    width: 350px;

    .select-resource-booking {
      .ng-dropdown-panel-items.scroll-host {
        max-height: 133px;
      }
    }
  }

  .warning {
    margin-top: 8px;
  }

  //#region timeline
  .timeline-box {
    margin-top: 8px;
  }

  .timeline {
    width: 752px;
    overflow: auto hidden;
    display: inline-block;
    vertical-align: top;
    border: 1px solid #e0e0e0;
    margin-bottom: 10px;

    .timeline-header {
      color: #222222;
      font-size: 14px;
      height: 17px;
      padding: 5px;
      font-weight: 700;
      left: 0px;
      position: sticky;
      border-bottom: 1px solid #e0e0e0;
    }

    .timeline-content {
      display: flex;

      table {
        table-layout: fixed;
        color: #222222;
        font-size: 14px;
        height: 50px;
        border-collapse: collapse;
      }

      table th {
        width: 80px;
        font-weight: 400;
        //text-align: left;
      }

      thead th,
      td {
        border: 1px solid #e0e0e0;
        border-bottom-color: transparent;
        border-top-color: transparent;
      }

      thead th:first-child {
        border-left-color: transparent;
      }

      .top-range {
        height: 20px;
        padding-left: 4px;
        text-align: left;
      }

      .timeline-phase-header {
        font-size: smaller;
        white-space: nowrap;
        width: 100%;
        border-top: 1px solid #c6c6c6;
        border-left: 1px solid #c6c6c6;
        height: 70px;

        &-hour {
          padding-left: 5px;
          border-bottom: 1px solid #c6c6c6;
        }
      }

      .hour-indicator {
        height: 50%;
        margin: 10px 0;
      }

      .hour-indicator-middle {
        background: #FFFFFF;
        width: 7.5px;
        border: none;
        height: 20px;
      }

      table tbody th {
        border-right: 1px solid #e0e0e0;
        height: 5px;
      }

      .hour-indicator-middle:nth-child(12n),
      .hour-indicator-middle:nth-child(6n) {
        margin: 5px 0;
        border-right: 1px solid #e0e0e0;
      }

      .styleBooking {
        background: #FFDD00 !important;
      }

      .styleBooked {
        background: #00144C;
      }

      .styleClosedHour {
        background: #00144C;
      }

      .styleCloseHour {
        background: #00144C;
      }

      .styleAvailableTime {
        background: #FFFFFF;
      }

      .timeline-indicator-line {
        top: 0;
        left: 0px;
        width: 0px;
        height: 50px;
        border-left: 1px solid #c6c6c6;
      }

      .timeline-indicator-line:last-child {
        top: 0;
        left: 0px;
        width: 0px;
        height: 50px;
        border-left: none;
      }
    }

    .selected {
      position: absolute;
      border-left: 1px dashed #c6c6c6;
      font-weight: bold;
      box-shadow: 0px 0px 3px 1px gray;
    }

    .mat-form-field-appearance-outline .mat-form-field-infix,
    .mat-form-field-suffix {
      top: 1em !important;
    }


  }

  .timeline-instruction {
    margin-left: 15px;
    vertical-align: top;
    margin-top: -10px;
    display: inline-block;
    width: 240px;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .d-flex {
      align-items: center;
    }

    .timeline-sample {
      text-align: center;
      border-style: solid;
      cursor: pointer;
      width: 5px;
    }

    .current-booking {
      height: 20px;
      line-height: 21px;
      font-size: 16px;
      border-width: 1px;
      width: 14px;
      margin-right: 10px;
      background-color: #FFDD00;
    }

    .out-of-booking {
      height: 20px;
      line-height: 21px;
      font-size: 16px;
      border-width: 1px;
      width: 14px;
      margin-right: 10px;
      background-color: #00144C;
    }
  }

  .select-more-options {
    width: 212px;

    .ng-dropdown-panel-items {
      margin-bottom: 4px;
      height: unset !important;
    }

    .ng-arrow-wrapper .ng-arrow {
      transform: rotate(-135deg) !important;
      -webkit-transform: rotate(-135deg) !important;
    }

    .ng-select-opened>.ng-select-container .ng-arrow {
      transform: rotate(45deg) !important;
      -webkit-transform: rotate(45deg) !important;
    }
  }

  .mat-datepicker-toggle-active {
    color: #04529C !important;
  }

  .mat-button-wrapper {
    color: #222222 !important;

    .mat-calendar-arrow {
      color: #222222 !important;
    }
  }

  .mat-button-focus-overlay {
    background: #222222 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: $btn-default-blue;
  }

  .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
      height: 0 !important;
  }
  //#endregion
}

.mat-datepicker-content .mat-calendar-body-label {
  color: transparent;

  &[colspan="7"] {
    display: none;
  }
}


//#region material common
.ng-select {
  cursor: pointer;

  .ng-dropdown-panel {
    animation: growDown 250ms ease-in-out forwards;
    transform-origin: top center;
    transition: 100ms 25ms linear;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0);
      opacity: 0;
    }

    50% {
      transform: scaleY(1.1);
      opacity: 0;
    }

    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }

  .ng-select-container {
    border-color: #e0e0e0 !important;
    height: 43px !important;
    box-shadow: 0px 0px 8px #00000014 !important;
  }

  &.ng-select-focused.ng-select-opened {
    .ng-select-container {
      border-color: $btn-default-blue  !important;
    }
  }

  .ng-arrow-wrapper {
    width: 24px;
    padding-right: 19px;
    margin-top: -7px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    display: none !important;
  }

  &.ng-select-opened.ng-select-bottom>.ng-select-container {
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  &.ng-invalid.ng-touched .ng-select-container {
    border-color: #ed323b !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
  }

  .ng-value,
  .ng-option-label {
    color: #222222;
    font-weight: 400;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ng-arrow-wrapper .ng-arrow {
    height: 0;
    width: 0;
    position: relative;
    border: solid #222222;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &.ng-select-opened>.ng-select-container .ng-arrow {
    top: 0 !important;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    border: solid #222222;
    border-width: 0 2px 2px 0;
  }

  .ng-dropdown-panel {
    &.ng-select-bottom {
      &.ng-select-bottom {
        border: none;
      }

      .ng-dropdown-panel-items.scroll-host {
        margin-top: 5px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 8px #00000029;
        border-radius: 4px;

        .ng-option {
          background: #FFFFFF;
          color: #222222;
          padding: 12px;
          text-align: left;
          height: 33px;
          display: flex;
          align-items: center;
        }

        .ng-option.ng-option-selected {
          background: #04529C !important;
          padding: 12px !important;

          .ng-option-label {
            color: #FFFFFF !important;
            font-weight: 400 !important;
          }
        }

        .ng-option:hover {
          background: #04529C !important;
          padding: 12px !important;

          .ng-option-label {
            color: #FFFFFF !important;
            font-weight: 400 !important;
          }
        }
      }
    }

    border: none !important;

    .ng-dropdown-panel-items {
      background: #FFFFFF 0 0 no-repeat padding-box !important;
      box-shadow: 0 4px 8px #00000029 !important;
      border-radius: 4px !important;
      //max-height: 165px;
      //min-height: 33px;

      .ng-option {
        background: #FFFFFF;
        color: #222222;
        padding: 12px;
        text-align: left;
        height: 33px;
        display: flex;
        align-items: center;
      }

      .ng-option.ng-option-selected {
        background: #04529C !important;
        padding: 12px !important;

        .ng-option-label {
          color: #FFFFFF !important;
          font-weight: 400 !important;
        }
      }

      .ng-option:hover {
        background: #04529C !important;
        padding: 12px !important;

        .ng-option-label {
          color: #FFFFFF !important;
          font-weight: 400 !important;
        }
      }
    }

    &.ng-select-bottom {
      margin-top: 4px !important;
      border-radius: 4px;
    }
  }
}

.ng-dropdown-panel {

  &.ng-select-bottom {
    border: none;
  }

  .ng-dropdown-panel-items.scroll-host {
    margin-top: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #00000029;
    border-radius: 4px;

    .ng-option {
      background: #FFFFFF;
      color: #222222;
      padding: 12px;
      text-align: left;
      height: 33px;
      display: flex;
      align-items: center;
    }

    .ng-option.ng-option-selected {
      background: #04529C !important;
      padding: 12px !important;

      .ng-option-label {
        color: #FFFFFF !important;
        font-weight: 400 !important;
      }
    }

    .ng-option:hover {
      background: #04529C !important;
      padding: 12px !important;

      .ng-option-label {
        color: #FFFFFF !important;
        font-weight: 400 !important;
      }
    }
  }

  border: none !important;

  .ng-dropdown-panel-items {
    background: #FFFFFF 0 0 no-repeat padding-box !important;
    box-shadow: 0 4px 8px #00000029 !important;
    border-radius: 4px !important;
    //max-height: 165px;
    //min-height: 33px;

    .ng-option {
      background: #FFFFFF;
      color: #222222;
      padding: 12px;
      text-align: left;
      height: 33px;
      display: flex;
      align-items: center;
    }

    .ng-option.ng-option-selected {
      background: #04529C !important;
      padding: 12px !important;

      .ng-option-label {
        color: #FFFFFF !important;
        font-weight: 400 !important;
      }
    }

    .ng-option:hover {
      background: #04529C !important;
      padding: 12px !important;

      .ng-option-label {
        color: #FFFFFF !important;
        font-weight: 400 !important;
      }
    }
  }

  &.ng-select-bottom {
    margin-top: 4px !important;
    border-radius: 4px;
  }
}

.ng-dropdown-panel.ng-select-bottom {
  border: none;

  .ng-dropdown-panel-items.scroll-host {
    margin-top: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #00000029;
    border-radius: 4px;

    .ng-option {
      background: #FFFFFF;
      color: #222222;
      padding: 12px;
      text-align: left;
      height: 33px;
      display: flex;
      align-items: center;
      font-size: 16px;
    }

    .ng-option.ng-option-selected {
      background: #04529C !important;
      padding: 12px !important;

      .ng-option-label {
        color: #FFFFFF !important;
        font-weight: 400 !important;
      }
    }

    .ng-option:hover {
      background: #04529C !important;
      padding: 12px !important;

      .ng-option-label {
        color: #FFFFFF !important;
        font-weight: 400 !important;
        border: none !important;

        .ng-dropdown-panel-items {
          background: #FFFFFF 0 0 no-repeat padding-box !important;
          box-shadow: 0 4px 8px #00000029 !important;
          border-radius: 4px !important;
          //max-height: 165px;
          //min-height: 33px;

          .ng-option {
            background: #FFFFFF;
            color: #222222;
            padding: 12px;
            text-align: left;
            height: 33px;
            display: flex;
            align-items: center;
          }

          .ng-option.ng-option-selected {
            background: #04529C !important;
            padding: 12px !important;

            .ng-option-label {
              color: #FFFFFF !important;
              font-weight: 400 !important;
            }
          }

          .ng-option:hover {
            background: #04529C !important;
            padding: 12px !important;

            .ng-option-label {
              color: #FFFFFF !important;
              font-weight: 400 !important;
            }
          }
        }

        &.ng-select-bottom {
          margin-top: 4px !important;
          border-radius: 4px;
        }
      }
    }
  }
}

.mat-menu-panel.menuItem {
  width: 222px !important;
  margin-bottom: 4px;

  .mat-menu-item {
    padding: 0 12px !important;
    text-align: left;
    text-decoration: none;
    background: #FFF;
    color: #222222 !important;
    text-transform: lowercase;
    font-size: 16px;
    height: 33px;
    display: flex;
    align-items: center;
  }

  .btn-title:first-letter {
    text-transform: uppercase;
  }

  .mat-menu-item:hover {
    background: #04529C;
    color: #FFFFFF !important;
  }
}

//#endregion

app-basic-dialog {
  .row-header__left {
    color: #222222;
    font-size: 22px;
    line-height: 31px;
  }

  .row-header__right {
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    right: 14px;
    top: 14px;

    span {
      font-size: 24px;
      color: #222222
    }
  }

  .row-body {
    padding: 1em;
    overflow: auto;
  }

  .row-footer {
    display: flex;
    justify-content: flex-end;
    margin: 1em;
  }

  .button-dialog {
    border: none;
    outline: none;
    font-size: 16px;
    border-radius: 4px;
    box-shadow: 0 0 8px #00000014;
    height: 43px;
    min-width: 76px;
    cursor: pointer;
    margin-left: 12px;
  }

  .btn-submit {
    background-color: #04529C;
    color: #FFFFFF;
    padding: 12px 20px;
  }

  .btn-cancel-dialog {
    border: 1px solid #00217F;
    color: #04529C;
    background-color: #FFFFFF;
    min-width: 76px;
    padding: 12px 20px;
  }

  .btn-cancel-dialog:hover {
    background-color: #f0f1f4;
  }

  .btn-submit:hover {
    background-color: #044a92;
  }
}

app-setup-resource,
app-edit-resource-dialog,
app-service-dialog {
  app-basic-dialog {
    .row-header__right {
      margin-bottom: 0 !important;

      .material-icons,
      button {
        font-size: 24px;
      }
    }
  }

  .btn {
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    box-shadow: 0 0 8px #00000014;
    line-height: 22px;
    padding: 10px 12px;
    height: 43px;

    &.mat-button-base {
      min-width: unset;
    }

    span {
      vertical-align: middle;

      &.material-icons:first-child:not(:last-child) {
        margin-right: 8px;
      }
    }

    &-basic {
      background-color: #FFFFFF;
      border: 1px solid #E0E0E0;
      color: #222222;
    }

    &-default {
      background-color: #04529C;
      color: #FFFFFF;
    }

    &-default-outline {
      background-color: #FFFFFF;
      border: 1px solid #00217F;
      color: #00217F !important;
    }
  }

  .custom-row-header__left {
    color: #222222;
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 31px;
    margin: 0;
  }

  .toast-inline-container {
    position: absolute;
    top: 30px;
    left: 0;
    width: calc(100% - 64px);

    .toast-container {
      position: absolute;
      top: -16px;
      left: 16px;
      width: 100%;
    }

    div[toast-component] {
      min-width: 300px;
      height: 51px;
      font-size: 16px;
      padding: 16px;
      border-radius: 4px;
      box-shadow: 0px 4px 20px #00000029;
      color: #FFFFFF;
    }

    .custom-toast-success {
      background-color: #069B4F;
    }

    .custom-toast-error {
      background-color: #ED323B;
    }

    .custom-toast-warning {
      background-color: #FFA500;
    }
  }

  input[type=text],
  input[type=number],
  textarea {
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    color: #222222;
    outline: 0;
    font-size: 16px;

    &:focus {
      border-color: #04529C;
    }

    &:disabled {
      background-color: #EFEFEF;
    }
  }

  * {
    box-sizing: border-box;
  }
}

@mixin override-mat-form-field-outline {
  .mat-mdc-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
      padding: 0.3em 0 !important;
      font-size: 16px;
    }

    .mat-mdc-form-field-flex {
      height: 43px;
      margin-top: 0;
    }

    .mat-mdc-form-field-outline {
      opacity: 1 !important;
      transition: none !important;
      height: 43px;
    }

    .mat-mdc-form-field-outline-thick {
      display: none;
    }

    .mat-mdc-form-field-outline-end,
    .mat-mdc-form-field-outline-start {
      border-color: #E0E0E0;
    }

    &.mat-focused {

      .mat-mdc-form-field-outline-end,
      .mat-mdc-form-field-outline-start {
        border-color: #04529C;
      }
    }
  }
}

.date-picker-add-time {

  .mat-calendar-body,
  .mat-calendar-table-header th {
    font-size: 12px;
  }

  .mat-calendar-body-label {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    color: transparent;
  }

  .mat-calendar-previous-button,
  .mat-calendar-next-button,
  .mat-calendar-period-button,
  .mat-calendar-body-cell-content {
    color: #222222;
  }

  .mat-calendar-body-selected {
    background-color: #04529C;
    color: #FFFFFF;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    background-color: #E5E8F2;
  }
}

.mat-mdc-select-panel {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 4px 8px #00000029 !important;
  border-radius: 4px !important;
  opacity: 1;

  .mat-mdc-option {
    height: 33px !important;
    padding: 6px 12px !important;

    .mdc-list-item__primary-text {
      color: #000000 !important;
    }

    &.mat-mdc-selected:not(.mat-mdc-option-disabled) {
      background: #04529C 0 0 no-repeat padding-box !important;
      color: #FFFFFF !important;
    }

    &:hover {
      background: #04529C 0 0 no-repeat padding-box !important;
      opacity: 1;

      .mdc-list-item__primary-text {
        color: #FFFFFF !important;
      }
    }
  }
}

.select-duration-panel.mat-mdc-select-panel {
  min-width: calc(100% + 0px) !important;
  height: 132px;
  margin-left: 6px;
  margin-top: 30px;
  margin-bottom: 34px;
}

.select-recurrence-panel.mat-mdc-select-panel {
  min-width: calc(100% + 46px) !important;
  height: 132px;
  margin-left: 6px;
  margin-top: 30px;
  margin-bottom: 34px;
}

app-setup-resource {
  app-basic-dialog {
    .row-header__right {
      .btn-close {
        font-size: 24px;
        width: 24px;
        height: 24px;
      }
    }

    .row-body {
      max-height: calc(80vh - 115px);
      padding: 0 32px;
    }

    .row-footer {
      margin: 16px 32px;
      padding-right: 18px;
    }
  }

  .row {
    display: flex;
  }

  .dialog-title {
    font-family: 'Poppins', 'Roboto', sans-serif;
    font-size: 32px;
    color: #00217F;
    line-height: 38px;
    margin: 27px 0 0;
  }

  .resource,
  .services,
  .available-time {
    &__header {
      margin-bottom: 16px;
    }

    &__title {
      font-size: 16px;
      color: #222222;
      line-height: 22px;
      margin: 0;
    }

    .btn-default {
      padding: 9px 20px 10px 12px;
    }

    .btn.btn-default:hover {
      background-color: #044a92;
    }
  }

  .resource {
    &__body {
      align-items: center;
    }

    &__select {
      min-width: 336px;
    }

    &__color-picker {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border: none;
      margin: 0 12px;
      padding: 0;
      cursor: pointer;
    }

    &__edit-resource {
      margin-left: auto;

      .btn-default {
        width: 168px;
      }
    }
  }

  .line-break {
    margin: 32px 0 16px;
    border: 0;
    border-top: 1px solid #E0E0E0;
  }

  .services {
    &__title {
      align-self: flex-end;
    }

    &__edit-btn {
      margin-left: auto;

      .btn-default {
        width: 158px;
      }
    }

    .services-list {
      &__header {
        border: 1px solid #E0E0E0;
        box-shadow: 0 4px 8px #00000014;
        padding: 4px 16px 4px 0;
        margin-bottom: 8px;
      }

      &__body {
        padding-bottom: 16px;

        .cdk-virtual-scroll-viewport {
          max-height: 380px;
          overflow-x: hidden;
        }

        .cdk-virtual-scroll-content-wrapper {
          width: 100%;
        }
      }

      &__name,
      &__time-required,
      &__description {
        align-self: center;
        color: #222222;
        font-size: 16px;
        line-height: 22px;
        padding: 8px 12px;
      }

      &__action {
        flex: 0 0;
        min-width: 18px;
        min-height: 18px;
        align-self: center;
        margin-left: 10px;
        margin-right:10px;

        .mat-checkbox-inner-container {
          width: 18px;
          height: 18px;
        }

        .mat-checkbox-frame {
          border-color: #666666;
        }

        .mat-checkbox-checked .mat-checkbox-background {
          background-color: #04529C;
        }
      }

      &__name {
        flex: 0 0 30%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__time-required {
        flex: 0 0 15%;
      }

      &__description {
        flex: 1 1 55%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .available-time {
    background-color: #F6F7FC;
    padding: 24px 32px 16px;
    margin: 0 -32px;

    .btn-basic {
      min-width: unset;
    }

    .btn.btn-basic:hover {
      background-color: #f0f1f4;
    }

    .mat-radio-button {
      flex: 0 1 50%;
      display: flex;
      align-items: center;
      background-color: #FFFFFF;
      border: 1px solid #E8E8E8;
      border-radius: 4px;
      padding: 16px;
      align-self: stretch;
      cursor: pointer;

      &.option-active {
        border: 1px solid #04529C;
      }

      &.mat-radio-checked .mat-radio-outer-circle {
        border-color: #04529C;
      }

      .mat-radio-inner-circle {
        background-color: #04529C;
      }

      label {
        font-size: 16px !important;
      }
    }

    label {
      font-weight: 400;
      color: #222222;
      white-space: normal;
    }

    b {
      text-transform: uppercase;
    }

    &__or {
      align-self: center;
      color: #222222;
      font-size: 16px;
      margin: 12px;
      flex: 0 0;
    }

    &__time-item {
      box-sizing: border-box;
      color: #222222;
      min-height: 34px;
      line-height: 22px;
      padding: 6px 17px;
      font-size: 16px;
      cursor: pointer;

      &.time-active {
        color: #FFFFFF;
        background-color: #04529C;
      }
    }

    &__edit {
      flex: 1 1 730px;
    }

    &__edit-textbox {
      border: 1px solid #E8E8E8;
      height: 185px;
      overflow-y: auto;
      background: #FFFFFF;
      margin-top: 12px;
    }

    &__action-textbox {
      margin-top: 4px;

      &>.btn-basic {
        padding: 9px 20px 10px 12px;
      }

      .edit-or-delete-btn-group {
        margin-left: auto;

        .btn-basic {
          width: 48px;
          padding: 0;
        }

        button:last-child {
          margin-left: 8px;
        }
      }
    }

    &__opening-hour {
      margin-left: 15px;
      display: flex;
      flex: 0 0 328px;
      width: 328px;
    }

    .opening-hour {
      width: 100%;

      &__title {
        background-color: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-shadow: 0 4px 8px #00000014;
        color: #222222;
        font-size: 16px;
        font-weight: 700;
        padding: 16px;
      }

      &__table {
        background-color: #FFFFFF;
        border: 1px solid #E8E8E8;
        padding: 16px;
        font-size: 16px;
        color: #222222;

        &>div {
          padding: 6px 0;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }

        .left {
          min-width: 118px;
          white-space: nowrap;
        }
      }
    }
  }

  .toast-inline-container {
    top: 24px;
  }
}

app-edit-resource-dialog {
  app-basic-dialog {
    .row-header {
      padding: 38px 32px 16px !important;
    }

    .row-body {
      padding: 0 32px;
    }

    .row-footer {
      margin: 16px 32px;
    }
  }

  @media only screen and (max-width: 1500px) {
    .row-body {
      max-height: 60vh;
    }
  }

  .custom-row-header__left {
    color: #222222;
    font-family: "Roboto", sans-serif !important;
    font-size: 22px;
    font-weight: 400;
    line-height: 31px;
    margin: 0;
  }

  .edit-resource {
    max-height: 472px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    padding: 16px 0;

    &__header {
      margin-bottom: 16px;
    }

    &__form {
      overflow-y: auto;
      padding: 0 16px;
      margin: 0 -16px 0;
    }

    &__title {
      font-size: 16px;
      color: #222222;
      line-height: 22px;
      margin: 0;
    }

    &__input-name {
      flex: 1 1 70%;

      input {
        min-height: 43px;
        height: 100%;
        padding: 13px 12px 11px;
        width: 100%;
      }
    }

    &__input-error {
      color: #ED323B;
      margin: 6px 0 12px;
    }

    &__color-picker {
      flex: 0 0 auto;
      min-width: 76px;
      background-color: #FFFFFF;
      border: 1px solid #E0E0E0;
      border-radius: 4px;
      box-shadow: 0 0 8px #00000014;
      margin: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        border: none;
        padding: 0;
        cursor: pointer;
      }

      span {
        cursor: pointer;
      }
    }

    &__delete {
      flex: 0 0 auto;
      min-width: 48px;

      .btn-basic {
        width: 48px;
        padding: 0;
      }
    }
  }

  .resource {
    &__item {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 2px;
      }
    }
  }

  .btn-add-resource {
    padding: 9px 20px 10px 12px;
  }

  .btn-add-resource:hover {
    background-color: #f0f1f4;
  }
}

app-add-resource-time {
  app-basic-dialog {
    .row-header {
      padding: 38px 32px 16px !important;
    }

    .custom-row-header__left {
      color: #222222;
      font-family: "Roboto", sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 31px;
      margin: 0;
    }

    .row-header__right {
      margin-bottom: 0 !important;

      .material-icons,
      button {
        font-size: 24px;
        width: 24px;
        height: 24px;
      }
    }

    .row-body {
      max-height: calc(80vh - 104px);
      padding: 0 32px;
    }

    .row-footer {
      margin: 16px 32px;
    }
  }

  .resource-time {
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;

    &__wrapper {
      max-height: calc(80vh - 164px);
      overflow-y: auto;
      margin: 0 -32px 0;
      padding: 32px 32px 16px;
    }

    label {
      color: #222222;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }

    .mat-checkbox-inner-container {
      width: 18px;
      height: 18px;
    }

    .mat-checkbox-frame {
      border-color: #666666;
    }

    .mat-checkbox-checked .mat-checkbox-background {
      background-color: #04529C;
    }

    .mat-datepicker-toggle .mat-icon {
      color: #222222;
    }

    .mat-mdc-form-field-wrapper {
      padding: 0;
      max-width: 216px;

      .mat-mdc-select-arrow {
        border-left: unset !important;
        border-right: unset !important;
      }

      .mat-mdc-select-value,
      .mat-mdc-input-element,
      .mat-icon {
        color: #222222;
      }
    }

    @include override-mat-form-field-outline();

    &__single-time {
      margin-bottom: 16px;
    }

    &__single-choose-time {
      flex: 0 1 auto;
      max-width: 216px;

      timepicker {
        display: block;
        margin-top: 12px;
        text-align: center;

        .form-group input[type=text] {
          color: #222222;
          outline: 0;
          border-radius: 4px;
          min-height: 43px;
          font-size: 16px;
          max-width: 42px;
          text-align: center;

          &:focus {
            border-color: #04529c;
          }
        }

        button.btn {
          background-color: #fff;
          border: 1px solid #E0E0E0;
          border-radius: 4px;
          box-shadow: 0 0 8px #00000014;
          color: #222222;
          font-size: 16px;
          outline: 0;
          padding: 0;
          text-transform: lowercase;
          width: 42px;
          height: 43px;
          font-weight: 500;
        }

        .btn-link {
          display: inline-block;
          color: #222222;
          padding: 4px 6px;

          span {
            box-sizing: border-box;
          }
        }
      }
    }

    &__single-to {
      align-self: center;
      flex: 0 0 auto;
      text-align: center;
      font-size: 16px;
      margin: 34px 24px 0;
    }

    &__single-date {
      .d-flex {
        align-items: center;
      }

      mat-form-field {
        width: 216px;
      }

      .make-recurring-section {
        display: flex;
        align-items: center;
        margin-left: 38px;
      }
    }

    &__recurring {
      margin-top: 16px;
      padding: 16px 0;
      border-top: 1px solid #E8E8E8;
    }

    &__recurring-pattern {
      .mat-form-field-outline {
        box-shadow: 0 0 8px #00000014;
        border-radius: 4px;
      }
    }

    &__recurring-pattern,
    &__recurring-every {
      margin-right: 13px;
      flex: 0 1;

      label {
        margin-bottom: 8px;
      }

      select,
      input {
        box-shadow: 0 0 8px #531A1A14;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 16px;
        height: 43px;
        outline: 0;

        &:focus {
          border-color: #04529c;
        }
      }

      select {
        width: 216px;
        padding: 0 12px;
      }

      input {
        width: 70px;
        text-align: left;
        padding: 13px 12px 11px 12px;
      }

      .wrapper-indicator {
        position: relative;
        height: 24px;
        width: 24px;
        margin-top: 0px;
        margin-right: 8px;
        margin-left: -30px;
      }

      .wrapper-indicator .up,
      .wrapper-indicator .down {
        position: absolute;
        right: 0;
        font-size: 12px;
        color: #222222;
      }

      .wrapper-indicator .up {
        cursor: pointer;
        top: -8px;
      }

      .wrapper-indicator .down {
        cursor: pointer;
        top: 8px;
      }

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .cdk-focused {
        background-color: transparent !important;
      }

      .cdk-focused.cdk-mouse-focused {}

    }

    &__recurring-every {
      flex: 0 1 90px;

      div {
        align-items: center;
        margin-top: 7px;

        label {
          margin: 0 0 0 8px;
        }
      }
    }

    &__recurring-body {
      margin-top: 16px;

      .weekly-wrapper,
      .monthly-wrapper,
      .yearly-wrapper {
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        flex-wrap: wrap;
        padding: 12px;
        margin-top: 6px;
      }

      .weekly-item,
      .monthly-item,
      .yearly-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .weekly-item {
        flex: 1 1 33.33%;
      }

      .monthly-item {
        flex: 1 1 16.66%;
      }

      .yearly-item {
        flex: 1 1 33.33%;

        &:nth-last-child(2),
        &:nth-last-child(3),
        {
        margin-bottom: 0;
      }
    }
  }

  &__recurring-footer {
    margin-top: 16px;
  }

  &__input-error {
    color: #ED323B;
    margin: 6px 0 12px;
  }

  &__input-success {
    color: #069B4F;
    margin: 6px 0 12px;
  }
}
}

app-service-dialog {
  app-basic-dialog {
    .row-header {
      padding: 38px 32px 16px !important;
    }

    .row-body {
      padding: 0 32px;
    }

    .row-footer {
      margin: 16px 32px;
    }
  }

  @media only screen and (max-width: 1500px) {
    .row-body {
      height: 70%;
    }
  }

  .service__instruction-text {
    padding-bottom: 16px;
    border-bottom: 1px solid #E0E0E0;
    color: #222222;
    font-size: 16px;
    line-height: 22px;
  }

  .edit-service {
    display: flex;
    flex-direction: column;
    max-height: 476px;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 15px;

    &__body {
      overflow-y: auto;
      padding: 0 16px;
      margin: 0 -16px 0;
      min-height: 300px;
    }

    &__service-table-header,
    &__service-table-row {
      display: flex;

      .mat-mdc-form-field-infix {
        padding: 0.5em 0 !important;
      }

      &>div {
        margin: 0 6px;

        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          margin-left: 0;
        }

        input,
        select {
          width: 100%;
          padding: 12px;
          height: 43px;
        }

        textarea {
          resize: none;
          padding: 3px 12px;
          height: 43px;
          width: 100%;
          min-width: 130px;

          &.service-desc-active {
            position: absolute;
            height: 94px;
            z-index: 1;
          }
        }

        select {
          padding: 0 0 0 12px;
          min-width: 80px;
        }
      }
    }

    &__service-table-header {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 99;

      &>div {
        color: #222222;
        font-size: 16px;
        font-weight: 700;
        padding: 16px 0;
      }
    }

    &__service-table-row {
      margin-bottom: 9px;

      .btn {
        padding: 0;
        width: 48px;
      }
    }

    &__service-name {
      flex: 0 0 303px;
      max-width: 303px;
    }

    &__service-time {
      flex: 1 0 107px;
      max-width: 107px;
      // box-shadow: 0px 0px 8px #00000014;
      .mat-mdc-select-arrow-wrapper {
        display: none !important;
      }
      .mat-mdc-form-field-infix {
        min-height: 46px;
      }

      .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
        height: 44px;
      }

      .mat-mdc-form-field-icon-suffix>.mat-icon {
        padding: 8px 10px 12px 0 !important;
      }
    }

    &__service-desc {
      flex: 2 1 55%;
      position: relative;
      min-width: 130px;
      max-height: 43px;
    }

    &__service-visible {
      flex: 0 0 48px;
      max-width: 48px;
      .mdc-button {
        min-width: 50px;
      }
    }

    &__service-action {
      flex: 0 0 48px;
      max-width: 48px;
      .mdc-button {
        min-width: 50px;
      }
    }

    &__input-error {
      color: #ED323B;
      margin: 6px 0 12px;
    }

    @include override-mat-form-field-outline();

    .mat-mdc-form-field-wrapper {
      padding: 0;
      margin: -3px 0 0 0;

      .mat-mdc-select-arrow {
        border-left: unset !important;
        border-right: unset !important;
      }

      .mat-mdc-select-value,
      .mat-icon {
        color: #222222;
      }
    }
  }

  .btn-add-service {
    padding: 9px 20px 10px 12px;
  }

  .btn-add-service:hover {
    background-color: #f0f1f4;
  }

  .mat-mdc-form-field {
    width: 100%;
    margin-bottom: 28px;
    display: -webkit-box !important;
  }
}

app-website-access-dialog {
  display: block;
  position: relative;
  @include button-template();

  .row-header {
    padding: 62px 33px 20px !important;
  }

  .row-body {
    padding: 0 33px !important;

    .dialog-body {
      &__label {
        font-size: 16px;
        font-weight: bold;
        color: #222222;
        margin: 12px 0;
      }

      &__text {
        font-size: 16px;
        color: #666666;
        margin-bottom: 12px;
      }
    }
  }

  .toast-inline-container {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;

    .toast-container {
      position: absolute;
      top: -16px;
      left: 16px;
      width: calc(100% - 64px);
    }

    div[toast-component] {
      min-width: 300px;
      height: 51px;
      font-size: 14px !important;
      padding: 16px;
      border-radius: 4px;
      box-shadow: 0px 4px 20px #00000029;
      color: #FFFFFF;
    }

    .custom-toast-success {
      background-color: #069B4F;
    }

    .custom-toast-error {
      background-color: #ED323B;
    }

    .custom-toast-warning {
      background-color: #FFA500;
    }
  }

  input[type="text"] {
    width: 80%;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    min-height: 43px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #222222;
    box-shadow: #531A1A14 0 0 8px;
    margin-right: 12px;
  }

  .btn-text {
    background: #FFFFFF;
    font-weight: 500;
  }

  p {
    width: 85%;
  }

  h2 {
    margin-top: 0 !important;
  }

  .row-footer {
    margin: 3em !important;

    .button-dialog.btn-submit {
      display: none !important;
    }
  }
}

app-time-confirm-dialog{
  display: block;
  position: relative;
  @include button-template();

  .row-header {
    padding: 62px 33px 20px !important;
  }

  .row-body {
    padding: 0 33px !important;

    .dialog-body {
      text-align: center;
      &__label {
        font-size: 22px;
        font-weight: bold;
        color: #222222;
        margin: 12px 0;
      }

      &__text {
        font-size: 22px;
        color: #040404;
        margin-bottom: 47px;
        margin-top: 51px;
      }
    }
  
    .toast-inline-container {
      position: absolute;
      top: 30px;
      left: 0;
      width: 100%;
  
      .toast-container {
        position: absolute;
        top: -16px;
        left: 16px;
        width: calc(100% - 64px);
      }
  
      div[toast-component] {
        min-width: 300px;
        height: 51px;
        font-size: 14px !important;
        padding: 16px;
        border-radius: 4px;
        box-shadow: 0px 4px 20px #00000029;
        color: #FFFFFF;
      }
  
      .custom-toast-success {
        background-color: #069B4F;
      }
  
      .custom-toast-error {
        background-color: #ED323B;
      }
  
      .custom-toast-warning {
        background-color: #FFA500;
      }
    }
  
    input[type="text"] {
      width: 80%;
      border: 1px solid #E0E0E0;
      border-radius: 4px;
      min-height: 43px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #222222;
      box-shadow: #531A1A14 0 0 8px;
      margin-right: 12px;
    }
  
    .btn-text {
      background: #FFFFFF;
      font-weight: 500;
    }
  
    h2 {
      margin-top: 0 !important;
    }
  
    .row-footer {
      margin: 3em !important;
  
      .button-dialog.btn-submit {
        display: none !important;
      }
    }
  }
}

app-patient-search-dialog {
  .row-header {
    &__left {
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-size: 22px !important;
      font-weight: 400 !important;
      line-height: 31px !important;
      letter-spacing: 0;
      color: #222222 !important;
    }

    padding: 38px 33px 16px !important;
  }

  .row-body {
    padding: 0 33px !important;
    max-height: calc(90vh - 160px);
  }

  @media only screen and (max-width: 1500px) {
    .row-body {
      padding: 0 33px !important;
      max-height: calc(90vh - 220px);
    }
  }

  .input-dialog-label {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0;
    color: #222222;
    opacity: 1;
    margin-bottom: 8px;
  }

  .patient-search {
    border-top: 1px solid #E0E0E0;

    .error-message {
      text-align: center;
      margin: 10px;
    }

    &__header {
      padding: 32px 0 16px;
      display: flex;

      mat-paginator .mat-paginator-container {
        justify-content: flex-start !important;
      }

      .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 18px 13px 12px 12px !important;
        border-top: 0 !important;
      }

      .mat-form-field-flex {
        padding: 0 !important;
      }

      &-number-input {
        .mat-form-field {
          width: 25%;
          margin: 0 10px;
        }

      }

      &-search-text {
        display: flex;
        align-items: center;
        width: 100%;

        &>div {
          margin-right: 12px;
          margin-top: 8px;
        }

        .mat-form-field {
          width: 90%;
          border-radius: 4px;
          min-height: 43px;
          font-size: 15px;
          box-shadow: #531A1A14 0 0 8px;
        }

        .mat-form-field-wrapper {
          padding-bottom: 0 !important;
        }

        mat-label {
          margin: 15px 10px 0 0;
        }

        .mat-form-field-wrapper {
          margin-top: 6px;
        }
      }
    }

    .not-data-found {
      margin: 20px;
      text-align: center;
      color: #222222;
      font-size: 16px;
    }

    &__table {
      height: 410px;
      overflow: auto;

      .mat-table {
        border-collapse: collapse;
        width: 100%;

        thead {
          background: #FFFFFF 0 0 no-repeat padding-box;
          box-shadow: 0 4px 8px #00000014;
          border: 1px solid #E0E0E0;
        }

        .mat-column-firstName {
          padding-left: 10px;
        }

        .mat-table tr,
        th,
        td {
          color: #222222;
          font-size: 16px;
        }

        tr.mat-row {
          height: 35px !important;
        }

        .mat-row {
          cursor: pointer;
        }

        .mat-row.selected {
          background: #04529C;

          .mat-cell {
            color: #FFFFFF;
          }
        }

        .mat-row:hover:not(.selected) {
          background-color: #04529C;

          .mat-cell {
            color: #FFFFFF;
          }
        }

        .mat-header-cell {
          font-size: 14px;
          font-weight: 700;
        }
      }

      &__footer {
        .pagination {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          list-style: none;
          align-items: center;

          a {
            color: #000000 !important;
            font-weight: 400 !important;
            text-decoration: none !important;
            font-size: 14px;
          }

          .page-item.active {
            border: 1px solid #000000;
            padding: 10px;
            border-radius: 5px;
          }
        }
      }

      margin-bottom: 20px;
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .select-page-size {
        width: 83px;
      }

      .item-label {
        margin-right: 12px;
      }

      .mat-paginator-page-size {
        display: none !important;
      }

      .mat-paginator-range-label {
        margin: 0 2.3px 0 15px !important;
      }
    }

    .mat-form-field-appearance-fill .mat-form-field-infix {
      padding: .25em 5px .75em 0;
    }

    input[type=text],
    input[type=password] {
      border: none !important;
      padding: unset !important;
      line-height: unset !important;
      height: unset !important;
    }

    // remove white background in case password autofill
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-transition-delay: 9999s;
      transition-delay: 9999s;
    }
  }

  .mat-select-value,
  .mat-paginator-page-size-label,
  .mat-paginator-range-label {
    font-size: 16px !important;
    color: #222222 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    height: 43px;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0 !important;
  }

  //.mat-form-field-appearance-outline {
  //  .mat-form-field-infix {
  //    padding: 0.3em 0 !important;
  //    font-size: 16px;
  //  }
  //  .mat-form-field-flex {
  //    height: 43px;
  //    margin-top: 0;
  //  }
  //  .mat-form-field-outline {
  //    opacity: 1 !important;
  //    transition: none !important;
  //    height: 43px;
  //  }
  //  .mat-form-field-outline-thick {
  //    display: none;
  //  }
  //
  //  .mat-form-field-outline-end,
  //  .mat-form-field-outline-start {
  //    border-color: #E0E0E0;
  //  }
  //
  //  &.mat-focused {
  //    .mat-form-field-outline-end,
  //    .mat-form-field-outline-start {
  //      border-color: #04529C;
  //    }
  //  }
  //}
}

app-settings {
  .label-field {
    .mat-checkbox {
      &.mat-checkbox-checked {
        .mat-checkbox-background {
          background: $btn-default-blue  !important;
        }

        .mat-checkbox-frame {
          border-color: $btn-default-blue  !important;
        }
      }

      .mat-checkbox-inner-container {
        width: 18px;
        height: 18px;
        background: #fff;

        .mat-checkbox-frame {
          border-color: #666666;
        }
      }
    }
  }

  .service-booking {
    padding: 16px 0;
    display: flex;
    align-items: center;

    &__checkbox {
      margin-right: 8px;

      .mat-checkbox-checked {
        .mat-checkbox-background {
          background: $btn-default-blue  !important;
        }

        .mat-checkbox-frame {
          border-color: $btn-default-blue  !important;
        }
      }

      .mat-checkbox-inner-container {
        width: 18px;
        height: 18px;
        background: #fff;

        .mat-checkbox-frame {
          border-color: #666666;
        }
      }
    }
  }
}

//Broadcast message styles
app-broadcast-message {
  background: $background-colour;
  border-top: solid 2px $primary-button-colour;

  button {
    border: none;
    outline: none;
    background: transparent;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .btn {
    border-radius: 99px;
    height: 40px;
    padding: 8px 16px;
    text-transform: uppercase;
  }

  .broadcast-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .patient-group,
  .message-template {
    background: #E5E5E5;
    padding: 15px;

    &__title {
      margin: 0 0 0 15px;
      padding-bottom: 15px;
    }

    &__content {
      padding: 10px 15px;
      background: $button-text-colour;
      border-radius: 8px;
      max-width: 65%;
    }

    &__input-error {
      color: $error-colour;
      font-weight: 500;
    }

    &__help-instruction {
      color: $primary-button-colour;
      font-weight: 700;
    }

    &__unsaved-hint {
      color: $error-colour;
      text-align: end;
      font-weight: 500;
      margin: 6px 0;
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
    }

    &__save {
      margin-right: 12px;
      text-align: end;
    }
  }

  .patient-group {
    &__input-group-name {
      margin-top: 24px;
      width: 100%;
    }

    &__age-range,
    &__gender-option {
      .patient-group__input-error {
        margin-left: 20px;
      }
    }

    &__settings {
      .option-group {
        align-items: center;
        display: flex;
        margin-left: 20px;

        input {
          margin: 0 4px 0 0;
        }

        label {
          font-weight: 400;
        }
      }

      .age-range-input {
        margin-left: 20px;

        span {
          margin: 0 10px;
        }
      }

      .age-range-error {
        font-weight: 500;
        color: $error-colour;
      }
    }

    &__active-ingredient {
      margin-left: 20px;

      .active-ingredient-form {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        position: relative;

        input {
          flex-grow: 1;
          z-index: 9;
        }
      }

      .active-ingredient-add {
        cursor: pointer;

        span {
          font-weight: 700;
          font-size: 40px;
          color: #000;
        }
      }

      .active-ingredient-autocomplete {
        position: absolute;
        width: calc(100% - 54px);
        background: $button-text-colour;
        border: 1px solid #c6c6c6;
        border-radius: 0 0 5px 5px;
        top: 36px;
        max-height: 300px;
        overflow-y: auto;
      }

      .active-ingredient-option {
        padding: 6px 12px;
        border-bottom: 1px solid #c6c6c6;

        &:hover {
          cursor: pointer;
          background: #eee;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .active-ingredient-list {
        height: 200px;
        padding: 0;

        option {
          padding: 5px;
        }
      }

      .active-ingredient-spinner {
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .active-ingredient-no-result {
        padding: 6px 12px;
        text-align: center;
      }

      .active-ingredient-delete {
        text-align: end;

        &-btn {
          color: $primary-button-colour;
          font-weight: 700;
          text-decoration: underline;
          text-transform: uppercase;
          cursor: pointer;
        }
      }
    }

    &__control-patient {
      border-top: 1px solid #333;
      margin-top: 15px;

      .control-patient-btn {
        cursor: pointer;
        padding: 0;
        color: $primary-button-colour;
        font-weight: 700;
        text-decoration: underline;
        text-transform: uppercase;
      }
    }

    &__footer {
      margin-top: 16px;
      align-items: flex-start;
      padding-top: 15px;
      width: 100%;
    }

    &__criteria-section {
      margin-top: 15px;
    }

    &__radio-section {
      display: flex;
      align-items: center;

      input {
        margin-top: 0;

        &:checked+label {
          font-weight: 500;
        }
      }

      label {
        font-weight: 400;
      }
    }
  }

  .message-template {
    margin-top: 24px;

    .d-flex {
      display: flex;
      width: 100%;
    }

    &__content {
      min-width: 65%;
      margin-right: 24px;
    }

    &__help {
      padding: 15px;
      align-self: flex-end;
      border-radius: 8px;
      background: #EEEEEE;

      &-title {
        margin-top: 0;
      }
    }

    &__form-control {
      margin-bottom: 16px;
      width: 80%;

      input,
      textarea {
        width: 100%;
        resize: none;
      }

      &:nth-child(4) {
        width: 100%;
      }
    }

    &__input-hint {
      text-align: end;
    }
  }

  .send-broadcast {
    text-align: end;
    padding: 20px 60px;
    margin-bottom: 50px;

    &__count-patient {
      margin-right: 32px;
      color: $primary-button-colour;
      text-decoration: underline;
      font-weight: 700;
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  .ck-editor__editable {
    min-height: 200px;

    * {
      word-break: break-word;
    }
  }
}

app-broadcast-patient-dialog {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;

  cdk-virtual-scroll-viewport {
    height: 100%;
    overflow-x: hidden;
  }

  .patient-dialog-header {
    border-top: 2px solid $bright-highlight;
    position: relative;
    background: $primary-button-colour;
    padding: 10px;
  }

  .patient-dialog-title {
    margin: 0;
    color: $button-text-colour;
  }

  .patient-dialog-content {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100% - 160px);
  }

  .patient-dialog-close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    outline: none;
    background: transparent;
    color: $button-text-colour;
  }

  .option-list__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .broadcast-patient-select {
    display: flex;
    padding: 6px 15px;
    background-color: #E5E5E5;

    &__option {
      flex: 0 0 115px;
      position: relative;
      text-align: center;
      z-index: 2;

      .input-wrapper {
        display: inline-block;
      }

      .select-control {
        cursor: pointer;
      }

      .material-icons-outlined {
        color: #FFFFFF;
      }

      .option-list {
        position: absolute;
        z-index: 99;
        top: 75%;
        left: 62%;
        background-color: #fff;
        border: 1px solid #E0E0E0;
        border-radius: 4px;

        div {
          text-align: center;
          border-bottom: 1px solid #E0E0E0;
          cursor: pointer;
          min-width: 80px;
          padding: 8px 16px;

          &:hover {
            background-color: #F3F3F3;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }

  .broadcast-patient-filter,
  .broadcast-patient-list {
    display: flex;
    padding: 15px 15px 5px 15px;

    &__name {
      flex: 1 1 65%;
    }

    &__include-exclude {
      flex: 0 0 30px;
      align-self: center;
      margin-right: 10px;
    }

    &__filter,
    &__actions {
      flex: 0 0 115px;
    }

    &__dob {
      flex: 0 1 25%;
      min-width: 110px;
    }

    select {
      text-align-last: center;
      padding-right: 26px;
    }

    input {
      width: 100%;
    }
  }

  .broadcast-patient-list {
    padding-right: 0;

    &__name {
      div {
        margin: 3px 0;
      }

      .name-wrapper {
        display: flex;
        margin: 0;
      }

      .carer-mode {
        margin-left: 4px;
        font-size: 13px;
      }
    }

    .patient-name-bold {
      font-weight: bold;
      color: $primary-button-colour;
    }

    &__dob {
      display: flex;
      align-items: center;
    }

    &__actions {
      position: relative;

      .slider {
        position: absolute;
        cursor: pointer;
        bottom: 0;
        left: 0;
        top: 0;
        right: 0;
        background-color: #ccc;
        transition: .3s;
        border-radius: 99px;

        &:before {
          position: absolute;
          content: '';
          height: 20px;
          width: 20px;
          left: 3px;
          top: 3px;
          background-color: white;
          transition: .3s;
          border-radius: 50%;
        }
      }

      .switch {
        position: absolute;
        top: 50%;
        left: 18%;
        transform: translate(0, -50%);
        display: inline-block;
        width: 50px;
        height: 26px;

        input {
          opacity: 0;
          width: 0;
          height: 0;
          position: absolute;

          &:checked+.slider {
            background-color: $primary-button-colour;
          }

          &:focus+.slider {
            box-shadow: 0 0 1px $primary-button-colour;
          }

          &:checked+.slider:before {
            -webkit-transform: translateX(24px);
            -ms-transform: translateX(24px);
            transform: translateX(24px);
          }
        }
      }
    }
  }

  .broadcast-patient-filter {
    background: #e5e5e5;
    padding-right: 18px;
    position: relative;

    &__select-actions {
      position: absolute;
      right: 18px;
      bottom: 0;
    }
  }

  .patient-dialog-footer {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;

    .count-patient-group {
      align-self: center;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      div {
        display: flex;
        align-items: center;
        margin-right: 20px;
        padding: 4px 0;
        white-space: nowrap;

        &:last-child {
          margin: 0;
        }

        span {
          margin-right: 4px;
        }
      }
    }

    &__actions {
      min-width: 120px;
      align-self: center;
    }

    .btn {
      text-transform: uppercase;
      padding: 10px 20px;
    }

    .clear-button {
      text-decoration: none;
    }
  }

  @media screen and (min-width: 769px) {
    .row-details {
      padding: 0 10px 0 0;
    }
  }
}

app-patientdetails {
  .patient-label {
    flex-shrink: 0;
  }

  .patient-group-badge {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    &__item {
      background-color: $primary-button-colour;
      color: $button-text-colour;
      white-space: nowrap;
      border-radius: 99px;
      padding: 6px;
      display: flex;
      align-items: center;
      margin: 0 5px 5px 0;

      span:last-child:not(:first-child) {
        padding: 0 6px 0 2px;
      }
    }
  }

  .patient-group-btn-edit {
    display: flex;
    align-items: center;

    .clear-button {
      margin: 0;
    }
  }

  app-message-inbox {

    h2,
    h3,
    h4 {
      border: 0;
      color: #FFFFFF;
      padding: 0;
    }

    a {
      color: #FFFFFF;
    }
  }
}

app-patient-group-update-dialog {
  display: block;
  padding: 15px;
  position: relative;

  .patient-group-header {
    margin-bottom: 15px;
  }

  .patient-group-title {
    margin: 0;
  }

  .patient-group-close {
    position: absolute;
    right: 0;
    top: 10px;
    border: none;
    outline: none;
    background: transparent;
  }

  .patient-group-content {
    margin-bottom: 15px;
  }

  .patient-group-footer {
    text-align: end;
  }

  .patient-group-badge {
    display: flex;
    flex-wrap: wrap;

    &__item {
      cursor: pointer;
      background-color: $bright-highlight;
      color: $button-text-colour;
      white-space: nowrap;
      border-radius: 99px;
      padding: 6px;
      display: flex;
      align-items: center;
      margin: 0 5px 5px 0;
      transition: .4s ease;

      &.active {
        transform: rotateX(360deg);
        background-color: $primary-button-colour  !important;
      }

      span:last-child:not(:first-child) {
        padding: 0 6px 0 2px;
      }
    }
  }

  .btn-done {
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 99px;
    color: $button-text-colour;
    background: $primary-button-colour;
    font-size: 14px;
    text-transform: uppercase;
  }
}

.confirm-dialog {
  .row-header {
    padding: 10px !important;
  }

  .row-body {
    font-size: 16px;
    color: #222222;
  }

  mat-dialog-container {
    border-radius: 10px !important;
  }

  .icon-warning {
    font-size: 60px;
    color: #FFA500;
  }

  .delete-content-dialog,
  .content-dialog {
    text-align: center;

    p {
      color: #222222;
      font-size: 16px;
    }
  }

  .button-dialog.btn-submit {
    background: $btn-primary;
    font-weight: 500;
  }
}

#view-print-section,
#booking-print-section,
#formFrame,
#bookingFormFrame {
  display: none;
}

.no-prescreen-form {
  text-align: center;

  .row-header {
    padding-top: 10px !important;
    padding-bottom: unset !important;
  }

  .row-body {
    font-size: 16px;
    color: #222222;
    margin: 20px 10px;
    text-align: left;
  }
}

.custom-drop-menu.mat-menu-panel {
  max-width: 147px !important;
}

.mat-menu-panel {
  min-height: unset !important;
}

app-settings {

  input[type="text"].input-opening-hours-note {
    width: 100%;
  }

}

.loader-new .loading-spiner {
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
}

.login-spinner {
  margin-right: 9px;
}

